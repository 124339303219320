import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "staff-container" }
const _hoisted_2 = { class: "staff-header" }
const _hoisted_3 = { class: "staff-week-days" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_RouterLink = _resolveComponent("RouterLink")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString($setup.activeStaffCount) + " compagnon" + _toDisplayString($setup.activeStaffCount > 1 ? "s" : "") + " actif" + _toDisplayString($setup.activeStaffCount > 1 ? "s" : ""), 1 /* TEXT */),
    _createVNode($setup["DataTable"], {
      value: $setup.staffListValue,
      class: "staff-table",
      "row-class": (data) => ({ 'staff-inactive': !data.contractActive })
    }, {
      default: _withCtx(() => [
        _createVNode($setup["Column"], {
          field: "name",
          header: "Nom"
        }, {
          body: _withCtx((slotProps) => [
            _createVNode(_component_RouterLink, {
              to: {
              name: 'staff-detail',
              params: {
                companySlug: $setup.staff.env.company_slug,
                staffSlug_staffID: `${slotProps.data.slug}_${slotProps.data.rawObject.id}`
              }
            },
              class: "staff-name-link"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(slotProps.data.name), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["Column"], {
          field: "domain",
          header: "Domaine"
        }),
        _createVNode($setup["Column"], {
          field: "rank",
          header: "Expérience"
        }),
        _createVNode($setup["Column"], {
          header: "Type de contrat",
          field: "contractType"
        }),
        _createVNode($setup["Column"], { header: "Jour de travail" }, {
          body: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.data.weekDays, (day, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: _normalizeClass([
                'staff-week-day-half-day',
                { '-morning': day.haldDays.morning},
                { '-afternoon': day.haldDays.afternoon},
              ])
                }, _toDisplayString(day.name), 3 /* TEXT, CLASS */))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["Column"], {
          field: "contractWeeklyHours",
          header: "Heures hebdomadaires"
        }),
        _createVNode($setup["Column"], { header: "Disponibilité" }, {
          body: _withCtx((slotProps) => [
            _createVNode($setup["Tag"], {
              value: slotProps.data.presence,
              severity: $setup.getPresenceSeverity(slotProps.data.presence)
            }, null, 8 /* PROPS */, ["value", "severity"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["Column"], null, {
          body: _withCtx((slotProps) => [
            _createVNode($setup["StaffSpeedDial"], {
              staff: slotProps.data.rawObject,
              "current-contract": slotProps.data.currentContract,
              "current-absence": slotProps.data.currentAbsence,
              presence: slotProps.data.presence
            }, null, 8 /* PROPS */, ["staff", "current-contract", "current-absence", "presence"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["value", "row-class"])
  ]))
}