<script setup>
import { Hammer, Plus } from "lucide-vue-next"

import useStaffStore from "../../stores/staff.js"

const staff = useStaffStore()
</script>

<template>
  <div class="staff-list-empty">
    <div class="staff-list-empty-card">
      <Hammer
        size="48"
        class="staff-list-empty-icon"
      />
      <div class="staff-list-empty-text">
        Vous n'avez pas encore ajouté de compagnon à votre atelier
      </div>
      <button
        class="staff-list-empty-button"
        @click="staff.openStaffModal()"
      >
        <Plus />
        Ajouter un compagnon
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../../../ui/static_src/ui/base/mixins";

.staff-list-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.staff-list-empty-card {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border: .4px solid var(--color-border-tertiary);
  border-radius: var(--radius);
  background-color: var(--content-bg--color-lighter);
  padding: 1rem;
  min-width: 35rem;
  min-height: 15rem;
  overflow: hidden;
}

.staff-list-empty-icon {
  position: absolute;
  width: 20rem;
  height: 20rem;
  transform: translate(-20%, -30%);
  color: var(--color-icon);
  opacity: .1;
  z-index: 1;
}

.staff-list-empty-text {
  @include subtitle;
}

.staff-list-empty-button {
  @include subtitle;
  background-color: var(--color-button-bg-submit);
  color: var(--color-text-quaternary);
  padding: .4rem .8rem;
  border-radius: var(--radius);
  cursor: pointer;
  z-index: 2;
  opacity: 1;

  &:hover {
    background-color: var(--color-button-bg-submit-full-hover);
    color: var(--color-text-quaternary);
    opacity: 1;
  }
}
</style>
