import { createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { class: "car-form-subtitle" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["for"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['car-incharge-form-block', { '-no-gap': $setup.isDropOff }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode($setup["Folder"], { size: "24" }),
      _cache[1] || (_cache[1] = _createTextVNode(" Dossier ")),
      _createElementVNode("button", {
        class: "car-form-dropdown",
        onClick: $setup.dropDown
      }, [
        _createVNode($setup["ChevronDown"], {
          class: _normalizeClass(['car-form-subtitle-chevron', { '-rotate': !$setup.isDropOff }]),
          size: "24"
        }, null, 8 /* PROPS */, ["class"])
      ])
    ]),
    _createVNode(_TransitionGroup, {
      name: "list",
      tag: "div",
      class: "car-incharge-form-block -no-padding"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.checkboxFields, (field, key) => {
          return (_openBlock(), _createElementBlock("button", {
            key: key,
            class: _normalizeClass(['car-form-checkbox', { '-hide': $setup.isDropOff }]),
            onClick: $event => ($setup.updateModel(key, !$props.modelValue[key], $event))
          }, [
            _createVNode($setup["Checkbox"], {
              "model-value": $props.modelValue[key],
              "input-id": key,
              name: key,
              binary: true,
              class: "checkbox-car-incharge"
            }, null, 8 /* PROPS */, ["model-value", "input-id", "name"]),
            _createElementVNode("label", {
              for: key,
              style: {"cursor":"pointer","color":"var(--color-text-secondary)"}
            }, _toDisplayString(field), 9 /* TEXT, PROPS */, _hoisted_3)
          ], 10 /* CLASS, PROPS */, _hoisted_2))
        }), 128 /* KEYED_FRAGMENT */)),
        (!$setup.isDropOff)
          ? (_openBlock(), _createBlock($setup["FloatLabel"], {
              key: 0,
              variant: "on",
              class: _normalizeClass(_ctx.end-_ctx.valid-_ctx.support-_ctx.date)
            }, {
              default: _withCtx(() => [
                _createVNode($setup["DatePicker"], {
                  id: "end_valid_support_date",
                  name: "end_valid_support_date",
                  "model-value": $props.modelValue.end_valid_support_date,
                  style: {"width":"100%"},
                  "date-format": "dd/mm/yy",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => $setup.updateModel('end_valid_support_date', value))
                }, null, 8 /* PROPS */, ["model-value"]),
                _cache[2] || (_cache[2] = _createElementVNode("label", { for: "end_valid_support_date" }, "Date de fin de validité de la prise en charge", -1 /* HOISTED */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["class"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ], 2 /* CLASS */))
}