import { createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "car-incharge-form-block" }
const _hoisted_2 = { class: "car-form-subtitle" }
const _hoisted_3 = { class: "car-incharge-form-block -row -no-padding" }
const _hoisted_4 = { class: "state-value" }
const _hoisted_5 = { class: "state-value" }
const _hoisted_6 = { class: "car-incharge-form-block" }
const _hoisted_7 = { class: "car-form-subtitle" }
const _hoisted_8 = { class: "car-incharge-form-block -row -no-padding" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["Clipboard"], { size: "24" }),
        _cache[14] || (_cache[14] = _createTextVNode(" Travail "))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["FloatLabel"], { variant: "on" }, {
          default: _withCtx(() => [
            _createVNode($setup["Select"], {
              id: "state",
              name: "state",
              "model-value": $setup.currentStateName,
              options: $setup.STATES_LIST.map(s => s.name),
              style: {"width":"12rem"},
              onValueChange: _cache[0] || (_cache[0] = (value) => $setup.updateModel('state', $setup.STATES_LIST.find(s => s.name === value).value))
            }, {
              value: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", {
                    class: _normalizeClass(`car__card-state -${$props.modelValue.state}`)
                  }, null, 2 /* CLASS */),
                  _createTextVNode(" " + _toDisplayString($setup.currentStateName), 1 /* TEXT */)
                ])
              ]),
              option: _withCtx(({ option }) => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", {
                    class: _normalizeClass(`car__card-state -${$setup.STATES_LIST.find(s => s.name === option).value}`)
                  }, null, 2 /* CLASS */),
                  _createTextVNode(" " + _toDisplayString(option), 1 /* TEXT */)
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["model-value", "options"]),
            _cache[15] || (_cache[15] = _createElementVNode("label", { for: "state" }, "État", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }),
        ($props.modelValue.state === 'breakage')
          ? (_openBlock(), _createBlock($setup["FloatLabel"], {
              key: 0,
              variant: "on"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["DatePicker"], {
                  id: "breakage_date",
                  name: "breakage_date",
                  "model-value": $props.modelValue.breakage_date,
                  style: {"min-width":"13rem"},
                  "date-format": "dd/mm/yy",
                  onValueChange: _cache[1] || (_cache[1] = (value) => $setup.updateModel('breakage_date', value))
                }, null, 8 /* PROPS */, ["model-value"]),
                _cache[16] || (_cache[16] = _createElementVNode("label", { for: "breakage_date" }, "Date de départ à la casse", -1 /* HOISTED */))
              ]),
              _: 1 /* STABLE */
            }))
          : ($props.modelValue.state === 'expertise')
            ? (_openBlock(), _createBlock($setup["FloatLabel"], {
                key: 1,
                variant: "on"
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["DatePicker"], {
                    id: "expertise_date",
                    name: "expertise_date",
                    "model-value": $props.modelValue.expertise_date,
                    style: {"min-width":"13rem"},
                    "date-format": "dd/mm/yy",
                    onValueChange: _cache[2] || (_cache[2] = (value) => $setup.updateModel('expertise_date', value))
                  }, null, 8 /* PROPS */, ["model-value"]),
                  _cache[17] || (_cache[17] = _createElementVNode("label", { for: "expertise_date" }, "Date de l'expertise", -1 /* HOISTED */))
                ]),
                _: 1 /* STABLE */
              }))
            : ($props.modelValue.state === 'waiting')
              ? (_openBlock(), _createBlock($setup["FloatLabel"], {
                  key: 2,
                  variant: "on"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["DatePicker"], {
                      id: "waiting_date",
                      name: "waiting_date",
                      "model-value": $props.modelValue.waiting_date,
                      style: {"min-width":"13rem"},
                      "date-format": "dd/mm/yy",
                      onValueChange: _cache[3] || (_cache[3] = (value) => $setup.updateModel('waiting_date', value))
                    }, null, 8 /* PROPS */, ["model-value"]),
                    _cache[18] || (_cache[18] = _createElementVNode("label", { for: "waiting_date" }, "En attente depuis", -1 /* HOISTED */))
                  ]),
                  _: 1 /* STABLE */
                }))
              : (_openBlock(), _createBlock($setup["FloatLabel"], {
                  key: 3,
                  variant: "on"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["DatePicker"], {
                      id: "start_work",
                      name: "start_work",
                      "model-value": $props.modelValue.start_work,
                      style: {"min-width":"13rem"},
                      "date-format": "dd/mm/yy",
                      onValueChange: _cache[4] || (_cache[4] = (value) => $setup.updateModel('start_work', value))
                    }, null, 8 /* PROPS */, ["model-value"]),
                    _cache[19] || (_cache[19] = _createElementVNode("label", { for: "start_work" }, "Date de début des réparations", -1 /* HOISTED */))
                  ]),
                  _: 1 /* STABLE */
                }))
      ]),
      ($props.modelValue.state === 'breakage')
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "car-form-checkbox",
            onClick: _cache[5] || (_cache[5] = $event => ($setup.updateModel('wreck_delivered', !$props.modelValue.wreck_delivered, $event)))
          }, [
            _createVNode($setup["Checkbox"], {
              "model-value": $props.modelValue.wreck_delivered,
              "input-id": "wreck_delivered",
              name: "wreck_delivered",
              binary: true,
              class: "checkbox-car-incharge"
            }, null, 8 /* PROPS */, ["model-value"]),
            _cache[20] || (_cache[20] = _createElementVNode("label", {
              for: "wreck_delivered",
              style: {"cursor":"pointer","color":"var(--color-text-secondary)"}
            }, "Épave livrée", -1 /* HOISTED */))
          ]))
        : _createCommentVNode("v-if", true),
      (['todo', 'coming', 'repairing', 'reassembly', 'finished', 'delivered'].includes($props.modelValue.state))
        ? (_openBlock(), _createBlock($setup["FloatLabel"], {
            key: 1,
            variant: "on"
          }, {
            default: _withCtx(() => [
              _createVNode($setup["DatePicker"], {
                id: "due_date",
                name: "due_date",
                "model-value": $props.modelValue.due_date,
                style: {"width":"100%"},
                "date-format": "dd/mm/yy",
                "min-date": new Date(),
                onValueChange: _cache[6] || (_cache[6] = (value) => $setup.updateModel('due_date', value))
              }, null, 8 /* PROPS */, ["model-value", "min-date"]),
              _cache[21] || (_cache[21] = _createElementVNode("label", { for: "due_date" }, "Date souhaitée de récupération du véhicule", -1 /* HOISTED */))
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode($setup["Clock"], { size: "24" }),
          _cache[22] || (_cache[22] = _createTextVNode(" Charge horaire "))
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode($setup["FloatLabel"], { variant: "on" }, {
            default: _withCtx(() => [
              ($props.modelValue.delayed_works.length > 0)
                ? _withDirectives((_openBlock(), _createBlock($setup["InputNumber"], {
                    key: 0,
                    id: "sheet_metal_work_hours",
                    name: "sheet_metal_work_hours",
                    "model-value": $props.modelValue.sheet_metal_work_hours,
                    "min-fraction-digits": 1,
                    style: {"width":"8rem"},
                    type: "number",
                    locale: "en-US",
                    readonly: "",
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = (value) => $setup.updateModel('sheet_metal_work_hours', value))
                  }, null, 8 /* PROPS */, ["model-value"])), [
                    [
                      _directive_tooltip,
                      `Impossible de modifier la valeur, des heures ont été reportées`,
                      void 0,
                      { top: true }
                    ]
                  ])
                : (_openBlock(), _createBlock($setup["InputNumber"], {
                    key: 1,
                    id: "sheet_metal_work_hours",
                    name: "sheet_metal_work_hours",
                    "model-value": $props.modelValue.sheet_metal_work_hours,
                    "min-fraction-digits": 1,
                    style: {"width":"8rem"},
                    type: "number",
                    locale: "en-US",
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = (value) => $setup.updateModel('sheet_metal_work_hours', value))
                  }, null, 8 /* PROPS */, ["model-value"])),
              _cache[23] || (_cache[23] = _createElementVNode("label", { for: "sheet_metal_work_hours" }, "Tôlerie", -1 /* HOISTED */))
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode($setup["FloatLabel"], { variant: "on" }, {
            default: _withCtx(() => [
              ($props.modelValue.delayed_works.length > 0)
                ? _withDirectives((_openBlock(), _createBlock($setup["InputNumber"], {
                    key: 0,
                    id: "painting_hours",
                    name: "painting_hours",
                    "model-value": $props.modelValue.painting_hours,
                    "min-fraction-digits": 1,
                    style: {"width":"8rem"},
                    type: "number",
                    locale: "en-US",
                    readonly: "",
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = (value) => $setup.updateModel('painting_hours', value))
                  }, null, 8 /* PROPS */, ["model-value"])), [
                    [
                      _directive_tooltip,
                      `Impossible de modifier la valeur, des heures ont été reportées`,
                      void 0,
                      { top: true }
                    ]
                  ])
                : (_openBlock(), _createBlock($setup["InputNumber"], {
                    key: 1,
                    id: "painting_hours",
                    name: "painting_hours",
                    "model-value": $props.modelValue.painting_hours,
                    "min-fraction-digits": 1,
                    style: {"width":"8rem"},
                    type: "number",
                    locale: "en-US",
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = (value) => $setup.updateModel('painting_hours', value))
                  }, null, 8 /* PROPS */, ["model-value"])),
              _cache[24] || (_cache[24] = _createElementVNode("label", { for: "painting_hours" }, "Peinture", -1 /* HOISTED */))
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode($setup["FloatLabel"], { variant: "on" }, {
            default: _withCtx(() => [
              ($props.modelValue.delayed_works.length > 0)
                ? _withDirectives((_openBlock(), _createBlock($setup["InputNumber"], {
                    key: 0,
                    id: "mechanic_hours",
                    name: "mecanic_hours",
                    "model-value": $props.modelValue.mechanic_hours,
                    "min-fraction-digits": 1,
                    style: {"width":"8rem"},
                    type: "number",
                    locale: "en-US",
                    readonly: "",
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = (value) => $setup.updateModel('mechanic_hours', value))
                  }, null, 8 /* PROPS */, ["model-value"])), [
                    [
                      _directive_tooltip,
                      `Impossible de modifier la valeur, des heures ont été reportées`,
                      void 0,
                      { top: true }
                    ]
                  ])
                : (_openBlock(), _createBlock($setup["InputNumber"], {
                    key: 1,
                    id: "mechanic_hours",
                    name: "mecanic_hours",
                    "model-value": $props.modelValue.mechanic_hours,
                    "min-fraction-digits": 1,
                    style: {"width":"8rem"},
                    type: "number",
                    locale: "en-US",
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = (value) => $setup.updateModel('mechanic_hours', value))
                  }, null, 8 /* PROPS */, ["model-value"])),
              _cache[25] || (_cache[25] = _createElementVNode("label", { for: "mechanic_hours" }, "Mécanique", -1 /* HOISTED */))
            ]),
            _: 1 /* STABLE */
          })
        ])
      ])
    ]),
    _createVNode($setup["Textarea"], {
      id: "background_note",
      name: "background_note",
      "model-value": $props.modelValue.background_note,
      rows: "5",
      cols: "30",
      style: {"resize":"none","width":"100%"},
      placeholder: "Écrire un commentaire",
      "onUpdate:modelValue": _cache[13] || (_cache[13] = (value) => $setup.updateModel('background_note', value))
    }, null, 8 /* PROPS */, ["model-value"])
  ], 64 /* STABLE_FRAGMENT */))
}