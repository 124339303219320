<script setup>
import DatePicker from "primevue/datepicker"
import FloatLabel from "primevue/floatlabel"
import InputText from "primevue/inputtext"
import { computed } from "vue"

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
})

const isCreation = computed(() => !props.modelValue.id)
const emit = defineEmits(["update:modelValue"])

const updateModel = (key, value, event = null) => {
  if (event) {
    event.preventDefault()
    event.stopPropagation()
  }

  emit("update:modelValue", {
    ...props.modelValue,
    [key]: value,
  })
}
</script>

<template>
  <div class="car-incharge-form-block">
    <FloatLabel variant="on">
      <InputText
        id="registration"
        name="registration"
        :model-value="modelValue.registration"
        style="width: 100%;"
        @update:model-value="(value) => updateModel('registration', value)"
      />
      <label for="registration">Plaque d'immatriculation</label>
    </FloatLabel>
    <div class="car-incharge-form-block -row -no-padding">
      <FloatLabel variant="on">
        <InputText
          id="brand"
          name="brand"
          :model-value="modelValue.brand"
          style="min-width: 12.5rem;"
          @update:model-value="(value) => updateModel('brand', value)"
        />
        <label for="brand">Marque</label>
      </FloatLabel>
      <FloatLabel variant="on">
        <InputText
          id="model"
          name="model"
          :model-value="modelValue.model"
          style="min-width: 12.5rem;"
          @update:model-value="(value) => updateModel('model', value)"
        />
        <label for="model">Modèle</label>
      </FloatLabel>
    </div>
    <FloatLabel
      v-if="!isCreation || modelValue.first_registration_date"
      variant="on"
    >
      <DatePicker
        id="first_registration_date"
        name="first_registration_date"
        :model-value="modelValue.first_registration_date"
        style="width: 100%;"
        date-format="dd/mm/yy"
        @update:model-value="(value) => updateModel('first_registration_date', value)"
      />
      <label for="first_registration_date">Date de 1ère immatriculation</label>
    </FloatLabel>
    <FloatLabel variant="on">
      <InputText
        id="foreground_note"
        name="foreground_note"
        :model-value="modelValue.foreground_note"
        style="width: 100%;"
        @update:model-value="(value) => updateModel('foreground_note', value)"
      />
      <label for="foreground_note">Note</label>
    </FloatLabel>
  </div>
</template>

<style lang="scss" scoped>
.car-incharge-form-block {
  display: flex;
  flex-direction: column;
  padding: .4rem 0rem;
  gap: 1rem;
  width: 100%;

  &.-row {
    flex-direction: row;
    justify-content: space-between;
  }

  &.-no-padding {
    padding: 0;
  }
}
</style>
