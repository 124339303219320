<script setup>
import { computed, onMounted, ref } from "vue"

import CalendarDay from "./CalendarDay.vue"
import CalendarNote from "./CalendarNote.vue"
import CalendarWeekHeader from "./CalendarWeekHeader.vue"

import { getIsoString, getNextFriday } from "../../../../../ui/static_src/ui/utils/date.js"
import { useComputedRef } from "../../composables/useComputedRef.js"
import { useCalendarStore } from "../../stores/calendar.js"

const props = defineProps({
  weekData: {
    type: Object,
    required: true,
  },
  paddingRight: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(["sentinel-intersected"])

const calendarStore = useCalendarStore()

const weekRef = ref(null)
const isVisible = ref(false)

const days = props.weekData?.monday
  ? Array.from({ length: 5 }, (_, i) => {
    const date = new Date(props.weekData.monday).setDate(props.weekData.monday.getDate() + i)
    return new Date(date)
  })
  : []

const getPublicHolidayName = (day) => {
  const publicHolidays = props.weekData?.data?.publicHolidays || []
  return publicHolidays.find((holiday) => holiday.date === getIsoString(day))?.name
}

const cars = computed(() => props.weekData?.monday ? calendarStore.getCarsOfWeek(props.weekData.monday) : [])
const delayedWorks = computed(() => props.weekData?.monday ? calendarStore.getDelayedWorksOfWeek(props.weekData.monday) : [])
const notes = computed(() => props.weekData?.data?.notes || [])
const dailyWorkCapacity = computed(() => props.weekData?.data?.dailyWorkCapacity || {})
const weekCapacity = computed(() => {
  return Object.entries(dailyWorkCapacity.value).reduce((acc, [date, hours]) => {
    if (getPublicHolidayName(new Date(date))) return acc
    acc.sheetMetalWork += hours.sheet_metal_work
    acc.paintWork += hours.painting
    acc.mechanicWork += hours.mechanic
    return acc
  }, { sheetMetalWork: 0, paintWork: 0, mechanicWork: 0 })
})

const mondayCars = computed(() => days.length > 0 ? calendarStore.getCarsOfDay(days[0]) : [])
const thursdayCars = computed(() => days.length > 0 ? calendarStore.getCarsOfDay(days[1]) : [])
const wednesdayCars = computed(() => days.length > 0 ? calendarStore.getCarsOfDay(days[2]) : [])
const tuesdayCars = computed(() => days.length > 0 ? calendarStore.getCarsOfDay(days[3]) : [])
const fridayCars = computed(() => days.length > 0 ? calendarStore.getCarsOfDay(days[4]) : [])

const mondayDelayedWorks = computed(() => days.length > 0 ? calendarStore.getDelayedWorksOfDay(days[0]) : [])
const thursdayDelayedWorks = computed(() => days.length > 0 ? calendarStore.getDelayedWorksOfDay(days[1]) : [])
const wednesdayDelayedWorks = computed(() => days.length > 0 ? calendarStore.getDelayedWorksOfDay(days[2]) : [])
const tuesdayDelayedWorks = computed(() => days.length > 0 ? calendarStore.getDelayedWorksOfDay(days[3]) : [])
const fridayDelayedWorks = computed(() => days.length > 0 ? calendarStore.getDelayedWorksOfDay(days[4]) : [])

const mondayCapacity = computed(() => days.length > 0 ? props.weekData.data.dailyWorkCapacity[getIsoString(days[0])] : {})
const thursdayCapacity = computed(() => days.length > 0 ? props.weekData.data.dailyWorkCapacity[getIsoString(days[1])] : {})
const wednesdayCapacity = computed(() => days.length > 0 ? props.weekData.data.dailyWorkCapacity[getIsoString(days[2])] : {})
const tuesdayCapacity = computed(() => days.length > 0 ? props.weekData.data.dailyWorkCapacity[getIsoString(days[3])] : {})
const fridayCapacity = computed(() => days.length > 0 ? props.weekData.data.dailyWorkCapacity[getIsoString(days[4])] : {})

const currentCars = useComputedRef(cars)
const currentDelayedWorks = useComputedRef(delayedWorks)

const currentMondayCars = useComputedRef(mondayCars)
const currentThursdayCars = useComputedRef(thursdayCars)
const currentWednesdayCars = useComputedRef(wednesdayCars)
const currentTuesdayCars = useComputedRef(tuesdayCars)
const currentFridayCars = useComputedRef(fridayCars)

const currentMondayDelayedWorks = useComputedRef(mondayDelayedWorks)
const currentThursdayDelayedWorks = useComputedRef(thursdayDelayedWorks)
const currentWednesdayDelayedWorks = useComputedRef(wednesdayDelayedWorks)
const currentTuesdayDelayedWorks = useComputedRef(tuesdayDelayedWorks)
const currentFridayDelayedWorks = useComputedRef(fridayDelayedWorks)

const currentMondayCapacity = useComputedRef(mondayCapacity)
const currentThursdayCapacity = useComputedRef(thursdayCapacity)
const currentWednesdayCapacity = useComputedRef(wednesdayCapacity)
const currentTuesdayCapacity = useComputedRef(tuesdayCapacity)
const currentFridayCapacity = useComputedRef(fridayCapacity)

onMounted(() => {
  // eslint-disable-next-line compat/compat
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        isVisible.value = true

        if (props.weekData.key === "sentinel-top" || props.weekData.key === "sentinel-bottom") {
          emit("sentinel-intersected", props.weekData.key)
        } else {
          calendarStore.currentWeekVisible = getNextFriday(props.weekData.monday)
        }
      }
    })
  }, { threshold: 0.0001 })

  if (weekRef.value) observer.observe(weekRef.value)

  return () => observer.disconnect()
})
</script>

<template>
  <div
    v-if="weekData.key === 'sentinel-top' || weekData.key === 'sentinel-bottom'"
    ref="weekRef"
    :class="['sentinel', {'-bottom': weekData.key === 'sentinel-bottom'}]"
  >
    <i
      class="pi pi-spin pi-cog loading-icon"
      style="font-size: 2rem"
    />
  </div>
  <div
    v-else-if="props.weekData?.data !== null"
    ref="weekRef"
    :class="['calendar-week', { '-visible': isVisible, '-padding-right': paddingRight }]"
  >
    <CalendarWeekHeader
      v-memo="[props.weekData.monday, currentCars, currentDelayedWorks, calendarStore.getReplacementCarsCount, dailyWorkCapacity]"
      :monday="props.weekData.monday"
      :cars="currentCars"
      :delayed-works="currentDelayedWorks"
      :replacement-capacity="calendarStore.getReplacementCarsCount"
      :week-capacity="weekCapacity"
      :delay-animation="props.weekData.delayAnimation"
    />
    <div class="calendar-week-notes">
      <CalendarNote
        v-for="note in notes"
        :key="note.pk"
        :note="note"
        :monday="props.weekData.monday"
      />
    </div>
    <div class="calendar-week-days">
      <CalendarDay
        v-memo="[days[0], currentMondayCars, currentMondayDelayedWorks, currentMondayCapacity]"
        :day="days[0]"
        :cars="currentMondayCars"
        :delayed-works="currentMondayDelayedWorks"
        :day-capacity="currentMondayCapacity"
        :delay-animation="props.weekData.delayAnimation"
        :public-holiday-name="getPublicHolidayName(days[0])"
      />
      <CalendarDay
        v-memo="[days[1], currentThursdayCars, currentThursdayDelayedWorks, currentThursdayCapacity]"
        :day="days[1]"
        :cars="currentThursdayCars"
        :delayed-works="currentThursdayDelayedWorks"
        :day-capacity="currentThursdayCapacity"
        :delay-animation="props.weekData.delayAnimation"
        :public-holiday-name="getPublicHolidayName(days[1])"
      />
      <CalendarDay
        v-memo="[days[2], currentWednesdayCars, currentWednesdayDelayedWorks, currentWednesdayCapacity]"
        :day="days[2]"
        :cars="currentWednesdayCars"
        :delayed-works="currentWednesdayDelayedWorks"
        :day-capacity="currentWednesdayCapacity"
        :delay-animation="props.weekData.delayAnimation"
        :public-holiday-name="getPublicHolidayName(days[2])"
      />
      <CalendarDay
        v-memo="[days[3], currentTuesdayCars, currentTuesdayDelayedWorks, currentTuesdayCapacity]"
        :day="days[3]"
        :cars="currentTuesdayCars"
        :delayed-works="currentTuesdayDelayedWorks"
        :day-capacity="currentTuesdayCapacity"
        :delay-animation="props.weekData.delayAnimation"
        :public-holiday-name="getPublicHolidayName(days[3])"
      />
      <CalendarDay
        v-memo="[days[4], currentFridayCars, currentFridayDelayedWorks, currentFridayCapacity]"
        :day="days[4]"
        :cars="currentFridayCars"
        :delayed-works="currentFridayDelayedWorks"
        :day-capacity="currentFridayCapacity"
        :delay-animation="props.weekData.delayAnimation"
        :public-holiday-name="getPublicHolidayName(days[4])"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.calendar-week {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border-radius: var(--radius);
  padding: 0 1rem;
  height: auto;
  margin: 1rem 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &.-visible {
    opacity: 1;
  }

  &.-padding-right {
    padding-right: 1.7rem;
  }
}

.calendar-week-notes {
  display: flex;
  flex-direction: row;
  gap: .5rem;
  width: 100%;
}

.calendar-week-days {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  width: 100%;
  min-height: 20rem;
}

.sentinel {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 10000px;

  &.-bottom {
    align-items: flex-start;
  }
}

.loading-icon {
  font-size: 2rem;
  color: var(--color-text-primary);
}
</style>
