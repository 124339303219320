import { createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "car-incharge-form-validate" }
const _hoisted_2 = {
  key: 0,
  class: "pi pi-spin pi-cog loading-icon"
}
const _hoisted_3 = {
  key: 1,
  class: "car-incharge-form-button",
  type: "submit"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Form"], {
    class: "car-incharge-form",
    resolver: $setup.resolver,
    onSubmit: $setup.onFormSubmit
  }, {
    default: _withCtx(($form) => [
      _createVNode($setup["RegistrationCar"], {
        car: $props.modelValue,
        onDataExtracted: $setup.handleRegistrationCarExtractedData
      }, null, 8 /* PROPS */, ["car"]),
      _createVNode($setup["CarDetails"], {
        "model-value": $props.modelValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = (updatedCar) => $setup.emit('update:modelValue', updatedCar))
      }, null, 8 /* PROPS */, ["model-value"]),
      _createVNode($setup["CarOwner"], {
        "model-value": $props.modelValue,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = (updatedCar) => $setup.emit('update:modelValue', updatedCar))
      }, null, 8 /* PROPS */, ["model-value"]),
      _createVNode($setup["CarFolder"], {
        "model-value": $props.modelValue,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = (updatedCar) => $setup.emit('update:modelValue', updatedCar))
      }, null, 8 /* PROPS */, ["model-value"]),
      _renderSlot(_ctx.$slots, "default", { form: $form }, undefined, true),
      _createVNode($setup["CarReplacement"], {
        "model-value": $props.modelValue,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = (updatedCar) => $setup.emit('update:modelValue', updatedCar))
      }, null, 8 /* PROPS */, ["model-value"]),
      _createVNode($setup["CarWork"], {
        "model-value": $props.modelValue,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = (updatedCar) => $setup.emit('update:modelValue', updatedCar))
      }, null, 8 /* PROPS */, ["model-value"]),
      _createElementVNode("div", _hoisted_1, [
        ($props.loading)
          ? (_openBlock(), _createElementBlock("i", _hoisted_2))
          : (_openBlock(), _createElementBlock("button", _hoisted_3, " Valider "))
      ])
    ]),
    _: 3 /* FORWARDED */
  }))
}