import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "car-followup" }
const _hoisted_2 = {
  key: 0,
  class: "car-followup-point -complete"
}
const _hoisted_3 = {
  key: 1,
  class: "car-followup-point -incomplete"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.isFollowupCompleteComputed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : (_openBlock(), _createElementBlock("div", _hoisted_3))
  ])), [
    [
      _directive_tooltip,
      $setup.carFollowupTooltipOptions,
      void 0,
      { left: true }
    ]
  ])
}