import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "absence-form-block" }
const _hoisted_2 = { class: "absence-form-block -margin-top" }
const _hoisted_3 = { class: "absence-form-validate" }
const _hoisted_4 = {
  key: 0,
  class: "pi pi-spin pi-cog loading-icon"
}
const _hoisted_5 = {
  key: 1,
  class: "absence-form-button",
  type: "submit"
}
const _hoisted_6 = {
  key: 0,
  class: "absence-form-timeline"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Dialog"], {
    visible: $setup.isAbsenceModalOpen,
    "onUpdate:visible": _cache[4] || (_cache[4] = $event => (($setup.isAbsenceModalOpen) = $event)),
    header: $setup.focusedAbsence?.id ? 'Modifier l\'absence en cours' : 'Ajouter une absence',
    modal: ""
  }, {
    default: _withCtx(() => [
      _createVNode($setup["Form"], {
        resolver: $setup.resolver,
        onSubmit: $setup.onFormSubmit
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode($setup["FloatLabel"], { variant: "on" }, {
              default: _withCtx(() => [
                _createVNode($setup["DatePicker"], {
                  id: "start_date",
                  name: "start_date",
                  "model-value": $setup.focusedAbsence.start_date,
                  style: {"width":"12.5rem"},
                  "date-format": "dd/mm/yy",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => $setup.updateModel('start_date', value))
                }, null, 8 /* PROPS */, ["model-value"]),
                _cache[5] || (_cache[5] = _createElementVNode("label", { for: "start_date" }, "Date de début", -1 /* HOISTED */))
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode($setup["FloatLabel"], { variant: "on" }, {
              default: _withCtx(() => [
                _createVNode($setup["Select"], {
                  id: "start_at",
                  name: "start_at",
                  "model-value": $setup.halfDayNames[$setup.focusedAbsence.start_at],
                  options: ['Matin', 'Après-midi'],
                  style: {"width":"12.5rem"},
                  onValueChange: _cache[1] || (_cache[1] = (value) => $setup.updateModel('start_at', $setup.halfDayValues[value]))
                }, null, 8 /* PROPS */, ["model-value"]),
                _cache[6] || (_cache[6] = _createElementVNode("label", { for: "start_at" }, "Demi-journée de début", -1 /* HOISTED */))
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode($setup["FloatLabel"], { variant: "on" }, {
              default: _withCtx(() => [
                _createVNode($setup["DatePicker"], {
                  id: "end_date",
                  name: "end_date",
                  "model-value": $setup.focusedAbsence.end_date,
                  style: {"width":"12.5rem"},
                  "date-format": "dd/mm/yy",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = (value) => $setup.updateModel('end_date', value))
                }, null, 8 /* PROPS */, ["model-value"]),
                _cache[7] || (_cache[7] = _createElementVNode("label", { for: "end_date" }, "Date de fin", -1 /* HOISTED */))
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode($setup["FloatLabel"], { variant: "on" }, {
              default: _withCtx(() => [
                _createVNode($setup["Select"], {
                  id: "end_at",
                  name: "end_at",
                  "model-value": $setup.halfDayNames[$setup.focusedAbsence.end_at],
                  options: ['Matin', 'Après-midi'],
                  style: {"width":"12.5rem"},
                  onValueChange: _cache[3] || (_cache[3] = (value) => $setup.updateModel('end_at', $setup.halfDayValues[value]))
                }, null, 8 /* PROPS */, ["model-value"]),
                _cache[8] || (_cache[8] = _createElementVNode("label", { for: "end_at" }, "Demi-journée de fin", -1 /* HOISTED */))
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            ($setup.loading)
              ? (_openBlock(), _createElementBlock("i", _hoisted_4))
              : (_openBlock(), _createElementBlock("button", _hoisted_5, _toDisplayString($setup.focusedAbsence?.id ? 'Modifier' : 'Ajouter'), 1 /* TEXT */))
          ]),
          ($setup.staffObject.absence_set.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _cache[9] || (_cache[9] = _createElementVNode("div", { class: "absence-form-title" }, " Historique ", -1 /* HOISTED */)),
                _createVNode($setup["AbsenceTimeline"], { "is-dialog": "" })
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible", "header"]))
}