<script setup>
import { Clipboard, Clock } from "lucide-vue-next"
import Checkbox from "primevue/checkbox"
import DatePicker from "primevue/datepicker"
import FloatLabel from "primevue/floatlabel"
import InputNumber from "primevue/inputnumber"
import Select from "primevue/select"
import Textarea from "primevue/textarea"
import { computed } from "vue"

import { STATES_LIST } from "../../../utils/states.js"

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
})

const currentStateName = computed(() => {
  return STATES_LIST.find(s => s.value === props.modelValue.state).name
})

const emit = defineEmits(["update:modelValue"])

const updateModel = (key, value, event = null) => {
  if (event) {
    event.preventDefault()
    event.stopPropagation()
  }

  emit("update:modelValue", {
    ...props.modelValue,
    [key]: value,
  })
}
</script>

<template>
  <div class="car-incharge-form-block">
    <div class="car-form-subtitle">
      <Clipboard size="24" />
      Travail
    </div>
    <div class="car-incharge-form-block -row -no-padding">
      <FloatLabel variant="on">
        <Select
          id="state"
          name="state"
          :model-value="currentStateName"
          :options="STATES_LIST.map(s => s.name)"
          style="width: 12rem;"
          @value-change="(value) => updateModel('state', STATES_LIST.find(s => s.name === value).value)"
        >
          <template #value>
            <div class="state-value">
              <div :class="`car__card-state -${modelValue.state}`" />
              {{ currentStateName }}
            </div>
          </template>
          <template #option="{ option }">
            <div class="state-value">
              <div :class="`car__card-state -${STATES_LIST.find(s => s.name === option).value}`" />
              {{ option }}
            </div>
          </template>
        </Select>
        <label for="state">État</label>
      </FloatLabel>
      <FloatLabel
        v-if="modelValue.state === 'breakage'"
        variant="on"
      >
        <DatePicker
          id="breakage_date"
          name="breakage_date"
          :model-value="modelValue.breakage_date"
          style="min-width: 13rem;"
          date-format="dd/mm/yy"
          @value-change="(value) => updateModel('breakage_date', value)"
        />
        <label for="breakage_date">Date de départ à la casse</label>
      </FloatLabel>
      <FloatLabel
        v-else-if="modelValue.state === 'expertise'"
        variant="on"
      >
        <DatePicker
          id="expertise_date"
          name="expertise_date"
          :model-value="modelValue.expertise_date"
          style="min-width: 13rem;"
          date-format="dd/mm/yy"
          @value-change="(value) => updateModel('expertise_date', value)"
        />
        <label for="expertise_date">Date de l'expertise</label>
      </FloatLabel>
      <FloatLabel
        v-else-if="modelValue.state === 'waiting'"
        variant="on"
      >
        <DatePicker
          id="waiting_date"
          name="waiting_date"
          :model-value="modelValue.waiting_date"
          style="min-width: 13rem;"
          date-format="dd/mm/yy"
          @value-change="(value) => updateModel('waiting_date', value)"
        />
        <label for="waiting_date">En attente depuis</label>
      </FloatLabel>
      <FloatLabel
        v-else
        variant="on"
      >
        <DatePicker
          id="start_work"
          name="start_work"
          :model-value="modelValue.start_work"
          style="min-width: 13rem;"
          date-format="dd/mm/yy"
          @value-change="(value) => updateModel('start_work', value)"
        />
        <label for="start_work">Date de début des réparations</label>
      </FloatLabel>
    </div>
    <button
      v-if="modelValue.state === 'breakage'"
      class="car-form-checkbox"
      @click="updateModel('wreck_delivered', !modelValue.wreck_delivered, $event)"
    >
      <Checkbox
        :model-value="modelValue.wreck_delivered"
        input-id="wreck_delivered"
        name="wreck_delivered"
        :binary="true"
        class="checkbox-car-incharge"
      />
      <label
        for="wreck_delivered"
        style="cursor: pointer; color: var(--color-text-secondary);"
      >Épave livrée</label>
    </button>
    <FloatLabel
      v-if="['todo', 'coming', 'repairing', 'reassembly', 'finished', 'delivered'].includes(modelValue.state)"
      variant="on"
    >
      <DatePicker
        id="due_date"
        name="due_date"
        :model-value="modelValue.due_date"
        style="width: 100%;"
        date-format="dd/mm/yy"
        :min-date="new Date()"
        @value-change="(value) => updateModel('due_date', value)"
      />
      <label for="due_date">Date souhaitée de récupération du véhicule</label>
    </FloatLabel>
    <div class="car-incharge-form-block">
      <div class="car-form-subtitle">
        <Clock size="24" />
        Charge horaire
      </div>
      <div class="car-incharge-form-block -row -no-padding">
        <FloatLabel variant="on">
          <InputNumber
            v-if="modelValue.delayed_works.length > 0"
            id="sheet_metal_work_hours"
            v-tooltip.top="`Impossible de modifier la valeur, des heures ont été reportées`"
            name="sheet_metal_work_hours"
            :model-value="modelValue.sheet_metal_work_hours"
            :min-fraction-digits="1"
            style="width: 8rem;"
            type="number"
            locale="en-US"
            readonly
            @update:model-value="(value) => updateModel('sheet_metal_work_hours', value)"
          />
          <InputNumber
            v-else
            id="sheet_metal_work_hours"
            name="sheet_metal_work_hours"
            :model-value="modelValue.sheet_metal_work_hours"
            :min-fraction-digits="1"
            style="width: 8rem;"
            type="number"
            locale="en-US"
            @update:model-value="(value) => updateModel('sheet_metal_work_hours', value)"
          />
          <label for="sheet_metal_work_hours">Tôlerie</label>
        </FloatLabel>
        <FloatLabel variant="on">
          <InputNumber
            v-if="modelValue.delayed_works.length > 0"
            id="painting_hours"
            v-tooltip.top="`Impossible de modifier la valeur, des heures ont été reportées`"
            name="painting_hours"
            :model-value="modelValue.painting_hours"
            :min-fraction-digits="1"
            style="width: 8rem;"
            type="number"
            locale="en-US"
            readonly
            @update:model-value="(value) => updateModel('painting_hours', value)"
          />
          <InputNumber
            v-else
            id="painting_hours"
            name="painting_hours"
            :model-value="modelValue.painting_hours"
            :min-fraction-digits="1"
            style="width: 8rem;"
            type="number"
            locale="en-US"
            @update:model-value="(value) => updateModel('painting_hours', value)"
          />
          <label for="painting_hours">Peinture</label>
        </FloatLabel>
        <FloatLabel variant="on">
          <InputNumber
            v-if="modelValue.delayed_works.length > 0"
            id="mechanic_hours"
            v-tooltip.top="`Impossible de modifier la valeur, des heures ont été reportées`"
            name="mecanic_hours"
            :model-value="modelValue.mechanic_hours"
            :min-fraction-digits="1"
            style="width: 8rem;"
            type="number"
            locale="en-US"
            readonly
            @update:model-value="(value) => updateModel('mechanic_hours', value)"
          />
          <InputNumber
            v-else
            id="mechanic_hours"
            name="mecanic_hours"
            :model-value="modelValue.mechanic_hours"
            :min-fraction-digits="1"
            style="width: 8rem;"
            type="number"
            locale="en-US"
            @update:model-value="(value) => updateModel('mechanic_hours', value)"
          />
          <label for="mechanic_hours">Mécanique</label>
        </FloatLabel>
      </div>
    </div>
  </div>
  <Textarea
    id="background_note"
    name="background_note"
    :model-value="modelValue.background_note"
    rows="5"
    cols="30"
    style="resize: none; width: 100%;"
    placeholder="Écrire un commentaire"
    @update:model-value="(value) => updateModel('background_note', value)"
  />
</template>

<style lang="scss" scoped>
@import '../../../../../../ui/static_src/ui/base/mixins';

.car-incharge-form-block {
  display: flex;
  flex-direction: column;
  padding: .4rem 0rem;
  gap: 1rem;
  width: 100%;

  &.-row {
    flex-direction: row;
    justify-content: space-between;
  }

  &.-no-padding {
    padding: 0;
  }
}

.car-form-subtitle {
  @include subtitle;
  display: flex;
  align-items: center;
  gap: .4rem;
  margin-top: .4rem;
}

.car-form-subtitle-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.car-form-checkbox {
  display: flex;
  align-items: center;
  gap: .6rem;
  width: fit-content;
  padding-inline: 0;
  padding-block: 0;

  &:hover {
    background-color: var(--content-bg--color-lighter);
  }
}

.state-value {
  display: flex;
  align-items: center;
  gap: .8rem;
}
</style>
