<script setup>
import { Plus, X } from "lucide-vue-next"
import AutoComplete from "primevue/autocomplete"
import Message from "primevue/message"
import { ref } from "vue"

import { getInsurances } from "../../services/api.js"
import { useCalendarStore } from "../../stores/calendar.js"
import { useCarStore } from "../../stores/car.js"

defineProps({
  form: {
    type: Object,
    required: true,
  },
})

const insurances = ref([])
const calendarStore = useCalendarStore()
const carStore = useCarStore()
const query = ref("")

const handleInsuranceSearch = async(event) => {
  const { data } = await getInsurances(event.query, carStore.focusedCar.insurances.map((insurance) => insurance.id))
  insurances.value = data
}

const handleInsuranceSelect = (event) => {
  carStore.addInsurance(event.value)
  query.value = ""
}
</script>

<template>
  <div class="insurances-container">
    <AutoComplete
      id="insurances"
      v-model="query"
      name="insurances"
      :suggestions="insurances"
      placeholder="Rechercher une assurance"
      :delay="300"
      @complete="handleInsuranceSearch"
      @item-select="handleInsuranceSelect"
    >
      <template #option="slotProps">
        <div class="insurance-selecter">
          {{ slotProps.option.name }}
        </div>
      </template>
      <template #empty>
        <div class="insurance-selecter -empty">
          Aucune assurance ne correspond à votre recherche
        </div>
      </template>
      <template #footer>
        <div class="insurance-selecter -footer">
          <button
            class="insurance-add"
            @click="calendarStore.insuranceModalOpen = true"
          >
            <Plus size="18" />
            Créer une assurance
          </button>
        </div>
      </template>
    </AutoComplete>
    <Message
      v-if="form.insurances?.invalid && query === ''"
      severity="error"
      size="small"
      variant="simple"
    >
      {{ form.insurances.error?.message }}
    </Message>
    <div class="insurance-box-container">
      <div
        v-for="insurance in carStore.focusedCar.insurances"
        :key="insurance.id"
        class="insurance-box"
      >
        {{ insurance.name }}

        <button
          class="remove-insurance"
          @click="carStore.removeInsurance(insurance)"
        >
          <X size="18" />
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src/ui/base/mixins';

.insurances-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: .6rem;
  margin-top: .6rem;
}

.insurances-selecter {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  height: 20rem;
}

.insurance-selecter.-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.insurance-selecter {
  @include body;
}

.insurance-add {
  @include body;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .4rem;
  padding: .4rem;
  border-radius: var(--radius);
  background-color: var(--color-button-bg-submit-full);
  color: var(--color-text-quaternary);
  width: 50%;
  margin: 1rem;

  &:hover {
    background-color: var(--color-button-bg-submit-full-hover);
  }
}

.insurance-box-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: .4rem;
  max-width: 26rem;
}

.insurance-box {
  @include body;
  display: flex;
  align-items: center;
  gap: .4rem;
  background-color: var(--color-button-bg-object);
  color: var(--color-text-quaternary);
  padding: .2rem .8rem;
  border-radius: var(--radius-max);
}

.remove-insurance {
  color: var(--color-text-quaternary);

  &:hover {
    color: var(--color-bg-warning);
    background-color: transparent;
  }
}
</style>
