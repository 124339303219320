<script setup>
import { ArrowBigRight, CalendarOff, Folder, Hammer, Info, Phone, StickyNote, Umbrella, UserRound } from "lucide-vue-next"
import { computed } from "vue"

import { getCalendarDate } from "../../../../../companies/static_src/companies/utils/cars.js"
import { getFullDate } from "../../../../../ui/static_src/ui/utils/date.js"
import { carObject } from "../../utils/car.js"

const props = defineProps({
  object: {
    type: Object,
    required: true,
  },
})

const car = computed(() => carObject(props.object))

const computeWork = (property) => {
  if (props.object.type === "car") {
    return car.value[property]
  }
  return car.value.delayed_works.reduce((acc, work) => {
    if (new Date(work.date).getTime() < props.object.work.date.getTime()) {
      return acc + work[property]
    }
    return acc
  }, 0) + car.value[property]
}

const isEndValidSUpportDateOver = computed(() => {
  if (["delivered", "finished"].includes(car.value.state)) return false
  if (!car.value.end_valid_support_date) return false
  return new Date(car.value.end_valid_support_date).getTime() < new Date(getCalendarDate(props.object)).getTime()
})

const sheetMetalWorkHours = computed(() => computeWork("sheet_metal_work_hours"))
const paintingHours = computed(() => computeWork("painting_hours"))
const mechanicHours = computed(() => computeWork("mechanic_hours"))
</script>

<template>
  <div class="car-details">
    <div
      v-if="car.due_date"
      class="car-details-block-text -warning"
    >
      <CalendarOff
        size="16"
        class="car-details-icon-text-warning"
      />
      <span>{{ getFullDate(new Date(car.due_date)) }}</span>
    </div>
    <div
      v-if="isEndValidSUpportDateOver"
      class="car-details-block-text -warning"
    >
      <span> Fin de validité de la prise en charge le {{ getFullDate(new Date(car.end_valid_support_date)) }}</span>
    </div>
    <div
      v-if="props.object.type === 'work'"
      class="car-details-title"
    >
      <ArrowBigRight size="24" />
      Heures de travail reportées
    </div>
    <div
      v-if="props.object.type === 'work'"
      class="car-details-block"
    >
      <div class="car-details-block-text -work">
        Tôlerie : <span>{{ object.work.sheet_metal_work_hours }}</span>
      </div>
      <div class="car-details-block-text -work">
        Peinture : <span>{{ object.work.painting_hours }}</span>
      </div>
      <div class="car-details-block-text -work">
        Mécanique : <span>{{ object.work.mechanic_hours }}</span>
      </div>
    </div>
    <div class="car-details-title">
      <Hammer size="24" />
      {{ props.object.type === 'work' ? 'Heures de travail terminées' : 'Heures de travail' }}
    </div>
    <div class="car-details-block">
      <div class="car-details-block-text -work">
        Tôlerie : <span>{{ sheetMetalWorkHours }}</span>
      </div>
      <div class="car-details-block-text -work">
        Peinture : <span>{{ paintingHours }}</span>
      </div>
      <div class="car-details-block-text -work">
        Mécanique : <span>{{ mechanicHours }}</span>
      </div>
    </div>
    <div class="car-details-title">
      <Info size="24" />
      Informations
    </div>
    <div class="car-details-block">
      <div class="car-details-block-text">
        <UserRound
          size="16"
          class="car-details-icon-text"
        />
        <span>{{ car.first_name }} {{ car.owner_last_name }}</span>
      </div>
      <div
        v-if="car.registration"
        class="car-details-block-text"
      >
        <Folder
          size="16"
          class="car-details-icon-text"
        />
        <span>{{ car.registration }}</span>
      </div>
      <div
        v-if="car.owner_phone_number"
        class="car-details-block-text"
      >
        <Phone
          size="16"
          class="car-details-icon-text"
        />
        <span>{{ car.owner_phone_number }}</span>
      </div>
      <div
        v-if="car.owner_phone_number_2"
        class="car-details-block-text"
      >
        <Phone
          size="16"
          class="car-details-icon-text"
        />
        <span>{{ car.owner_phone_number_2 }}</span>
      </div>
    </div>
    <div
      v-if="car.insurances.length > 0"
      class="car-details-title"
    >
      <Umbrella size="24" />
      Assurances
    </div>
    <div
      v-if="car.insurances.length > 0"
      class="insurances__box"
    >
      <div
        v-for="insurance in car.insurances"
        :key="insurance.id"
        class="insurance__box -calendar"
      >
        {{ insurance.name }}
      </div>
    </div>
    <div
      v-if="car.foreground_note"
      class="car-details-block-text"
    >
      <StickyNote
        size="16"
        class="car-details-icon-text"
      />
      <span>{{ car.foreground_note }}</span>
    </div>
    <div
      v-if="car.background_note"
      class="car-details-block-text"
    >
      <textarea
        v-model="car.background_note"
        class="form__textarea -calendar-car"
        rows="5"
        readonly
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src//ui//base/mixins';

.car-details {
  display: flex;
  flex-direction: column;
  gap: .4rem;
  width: 20rem;
}

.car-details-title {
  @include subtitle;
  display: flex;
  align-items: center;
  gap: .4rem;
  margin-top: .4rem;

  &:first-of-type {
    margin-top: 0;
  }
}

.car-details-block-text {
  @include body;
  display: flex;
  gap: .4rem;
  align-items: flex-start;
  justify-content: flex-start;

  &.-warning {
    color: var(--color-text-warning);
  }

  &.-work {
    color: var(--color-text-secondary);

    & > span {
      color: var(--color-text-primary);
    }
  }
}

.car-details-icon-text {
  margin-top: .3rem;
  color: var(--color-text-quaternary-secondary);
}

.insurance__box.-calendar {
  box-shadow: var(--p-select-overlay-shadow);
}

.form__textarea.-calendar-car {
  border: .1px solid var(--color-text-primary);
}

.car-details-icon-text-warning {
  color: var(--color-bg-warning);
  margin-top: .15rem;
}
</style>
