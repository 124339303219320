<script setup>
import { storeToRefs } from "pinia"
import { onMounted, watch } from "vue"

import EmptyStaffList from "../components/staff/EmptyStaffList.vue"
import StaffTable from "../components/staff/StaffTable.vue"
import StaffToolbar from "../components/staff/StaffToolbar.vue"

import useStaffStore from "../stores/staff.js"

const staff = useStaffStore()
const { isListEmpty } = storeToRefs(staff)

watch([isListEmpty], () => {
  if (isListEmpty.value) {
    staff.openStaffModal()
  }
})

onMounted(staff.init)
</script>

<template>
  <template v-if="!isListEmpty">
    <StaffToolbar />
    <div
      v-if="staff.staffList.length === 0"
      class="staff-loading-data"
    >
      <i class="pi pi-spin pi-cog loading-icon" />
    </div>
    <StaffTable v-else />
  </template>
  <EmptyStaffList v-else />
</template>

<style lang="scss" scoped>
@import "../../../../ui/static_src/ui/base/mixins";

.staff-loading-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loading-icon {
  color: var(--color-text-primary);
  font-size: 2rem;
}
</style>
