import { createRouter, createWebHistory } from "vue-router"

import StaffDetailView from "../views/StaffDetailView.vue"
import StaffListView from "../views/StaffListView.vue"

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/:companySlug/compagnons/",
      name: "staff-list",
      component: StaffListView,
    },
    {
      path: "/:companySlug/:staffSlug_staffID/",
      name: "staff-detail",
      component: StaffDetailView,
    },
  ],
})

export default router
