import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "staff-detail-container" }
const _hoisted_2 = { class: "staff-detail" }
const _hoisted_3 = { class: "staff-detail-header" }
const _hoisted_4 = { class: "staff-detail-name" }
const _hoisted_5 = { class: "staff-detail-week-days" }
const _hoisted_6 = { class: "staff-timeline-container" }
const _hoisted_7 = {
  key: 0,
  class: "staff-timeline-header"
}
const _hoisted_8 = {
  key: 1,
  class: "staff-timeline-header"
}
const _hoisted_9 = {
  key: 0,
  class: "staff-timeline-container"
}
const _hoisted_10 = { class: "staff-timeline-header" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createTextVNode(_toDisplayString($setup.staffObject.first_name) + " " + _toDisplayString($setup.staffObject.last_name) + " ", 1 /* TEXT */),
          _createElementVNode("span", null, _toDisplayString($setup.domainNames[$setup.staffObject.domain]) + " " + _toDisplayString($setup.rankNames[$setup.staffObject.rank]), 1 /* TEXT */)
        ]),
        _createVNode($setup["Tag"], {
          severity: $setup.getPresenceSeverity($setup.isPresent),
          value: $setup.isPresent
        }, null, 8 /* PROPS */, ["severity", "value"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.weekDays, (day, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass([
            'staff-week-day-half-day',
            { '-morning': day.haldDays.morning},
            { '-afternoon': day.haldDays.afternoon},
          ])
          }, _toDisplayString(day.name), 3 /* TEXT, CLASS */))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _createElementVNode("div", _hoisted_6, [
        ($setup.staffObject.contract_set.length > 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode($setup["FileStack"], { size: "20" }),
              _cache[0] || (_cache[0] = _createTextVNode(" Contrats "))
            ]))
          : ($setup.staffObject.contract_set.length === 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode($setup["File"], { size: "20" }),
                _cache[1] || (_cache[1] = _createTextVNode(" Contrat "))
              ]))
            : _createCommentVNode("v-if", true),
        _createVNode($setup["ContractTimeline"])
      ]),
      ($setup.staffObject.absence_set.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode($setup["Calendar"], { size: "20" }),
              _createTextVNode(" Absence" + _toDisplayString($setup.staffObject.absence_set.length > 1 ? "s" : ""), 1 /* TEXT */)
            ]),
            _createVNode($setup["AbsenceTimeline"])
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}