<script setup>
import { asyncComputed } from "@vueuse/core/index.cjs"
import { computed, h } from "vue"
import { renderToString } from "vue/server-renderer"

import CarFollowUp from "../../../../../cars/static_src/cars/components/tooltips/CarFollowUp.vue"

import { isFollowupComplete } from "../../utils/cars.js"

const props = defineProps({
  car: {
    type: Object,
    required: true,
  },
})

const carFollowupTooltipOptions = asyncComputed(async() => {
  const htmlContent = await renderToString(h(CarFollowUp, { car: props.car }))
  return { escape: false, value: htmlContent }
})

const isFollowupCompleteComputed = computed(() => {
  return isFollowupComplete(props.car)
})
</script>

<template>
  <div
    v-tooltip.left="carFollowupTooltipOptions"
    class="car-followup"
  >
    <div
      v-if="isFollowupCompleteComputed"
      class="car-followup-point -complete"
    />
    <div
      v-else
      class="car-followup-point -incomplete"
    />
  </div>
</template>

<style lang="scss" scoped>
.car-followup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 .4rem;
  min-width: .4rem;
  height: 100%;

  &.-deleted {
    opacity: .4;
  }
}

.car-followup-point {
  width: .4rem;
  height: .4rem;
  border-radius: 50%;

  &.-incomplete {
    background-color: var(--car-followup-incomplete);
  }

  &.-complete {
    background-color: var(--car-followup-complete);
  }
}
</style>
