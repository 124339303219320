import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "contract-form-block" }
const _hoisted_2 = { class: "contract-form-block -margin-top -column" }
const _hoisted_3 = { class: "contract-form-subtitle" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["for"]
const _hoisted_6 = { class: "contract-form-block -margin-top -column" }
const _hoisted_7 = { class: "contract-form-subtitle" }
const _hoisted_8 = { class: "contract-form-block" }
const _hoisted_9 = { class: "contract-form-validate" }
const _hoisted_10 = {
  key: 0,
  class: "pi pi-spin pi-cog loading-icon"
}
const _hoisted_11 = {
  key: 1,
  class: "contract-form-button",
  type: "submit"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Dialog"], {
    visible: $setup.isContractModalOpen,
    "onUpdate:visible": _cache[4] || (_cache[4] = $event => (($setup.isContractModalOpen) = $event)),
    header: $setup.focusedContract?.id ? 'Modifier le contrat' : 'Ajouter un contrat',
    modal: ""
  }, {
    default: _withCtx(() => [
      _createVNode($setup["Form"], {
        resolver: $setup.resolver,
        onSubmit: $setup.onFormSubmit
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode($setup["FloatLabel"], { variant: "on" }, {
              default: _withCtx(() => [
                _createVNode($setup["Select"], {
                  id: "type",
                  name: "type",
                  "model-value": $setup.contractNames[$setup.focusedContract.type],
                  options: ['CDI', 'CDD', 'Indépendant'],
                  style: {"width":"12.5rem"},
                  onValueChange: _cache[0] || (_cache[0] = (value) => $setup.updateModel('type', $setup.contractValues[value]))
                }, null, 8 /* PROPS */, ["model-value"]),
                _cache[5] || (_cache[5] = _createElementVNode("label", { for: "type" }, "Type de contrat", -1 /* HOISTED */))
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode($setup["FloatLabel"], { variant: "on" }, {
              default: _withCtx(() => [
                _createVNode($setup["InputNumber"], {
                  id: "weekly_hours",
                  name: "weekly_hours",
                  "model-value": $setup.focusedContract.weekly_hours,
                  style: {"width":"12.5rem"},
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = (value) => $setup.updateModel('weekly_hours', value))
                }, null, 8 /* PROPS */, ["model-value"]),
                _cache[6] || (_cache[6] = _createElementVNode("label", { for: "weekly_hours" }, "Volume horaire par semaine (h)", -1 /* HOISTED */))
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode($setup["Clock"], { size: "24" }),
              _cache[7] || (_cache[7] = _createTextVNode(" Jours de travail "))
            ]),
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['Lundi matin', 'Lundi après-midi', 'Mardi matin', 'Mardi après-midi', 'Mercredi matin', 'Mercredi après-midi', 'Jeudi matin', 'Jeudi après-midi', 'Vendredi matin', 'Vendredi après-midi'], (day, index) => {
              return _createElementVNode("button", {
                key: index,
                class: "contract-form-checkbox",
                onClick: $event => ($setup.updateWeekDay(index, $event))
              }, [
                _createVNode($setup["Checkbox"], {
                  "model-value": $setup.weekDayValue(index),
                  "input-id": `week-half-day-${index}`,
                  name: `week-half-day-${index}`,
                  binary: true
                }, null, 8 /* PROPS */, ["model-value", "input-id", "name"]),
                _createElementVNode("label", {
                  for: `week-half-day-${index}`,
                  style: {"cursor":"pointer","color":"var(--color-text-secondary)"}
                }, _toDisplayString(day), 9 /* TEXT, PROPS */, _hoisted_5)
              ], 8 /* PROPS */, _hoisted_4)
            }), 64 /* STABLE_FRAGMENT */))
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode($setup["Calendar"], { size: "24" }),
              _cache[8] || (_cache[8] = _createTextVNode(" Dates "))
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode($setup["FloatLabel"], { variant: "on" }, {
                default: _withCtx(() => [
                  _createVNode($setup["DatePicker"], {
                    id: "start_date",
                    name: "start_date",
                    "model-value": $setup.focusedContract.start_date,
                    style: {"width":"12.5rem"},
                    "date-format": "dd/mm/yy",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = (value) => $setup.updateModel('start_date', value))
                  }, null, 8 /* PROPS */, ["model-value"]),
                  _cache[9] || (_cache[9] = _createElementVNode("label", { for: "start_date" }, "Date de début", -1 /* HOISTED */))
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode($setup["FloatLabel"], { variant: "on" }, {
                default: _withCtx(() => [
                  _createVNode($setup["DatePicker"], {
                    id: "end_date",
                    name: "end_date",
                    variant: $setup.focusedContract.type === 'cdi' && !$setup.focusedContract.end_date ? 'filled' : '',
                    "model-value": $setup.focusedContract.end_date,
                    "date-format": "dd/mm/yy",
                    style: {"width":"12.5rem"},
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = (value) => $setup.updateModel('end_date', value))
                  }, null, 8 /* PROPS */, ["variant", "model-value"]),
                  _cache[10] || (_cache[10] = _createElementVNode("label", { for: "end_date" }, "Date de fin", -1 /* HOISTED */))
                ]),
                _: 1 /* STABLE */
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            ($setup.loading)
              ? (_openBlock(), _createElementBlock("i", _hoisted_10))
              : (_openBlock(), _createElementBlock("button", _hoisted_11, _toDisplayString($setup.focusedContract?.id ? 'Modifier' : 'Ajouter'), 1 /* TEXT */))
          ])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible", "header"]))
}