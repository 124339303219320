<script setup>
import { orderBy } from "lodash-es"
import { storeToRefs } from "pinia"
import { Dialog } from "primevue"
import { ref, watch } from "vue"

import CarsNotUpdated from "../../../../../../notifications/static_src/notifications/components/CarsNotUpdated.vue"
import ReplacementNotReturned from "../../../../../../notifications/static_src/notifications/components/ReplacementNotReturned.vue"

import { getIsoString } from "../../../../../../ui/static_src/ui/utils/date.js"
import { getCarsInChargeNotUpdatedNotifications, getReplacementCarNoReturnedNotifications, markNotificationsAsRead } from "../../../services/api.js"
import { useCalendarStore } from "../../../stores/calendar.js"
import { getCalendarDate } from "../../../utils/cars.js"

const replacementsNotification = ref(null)
const carsNotification = ref(null)

const calendarStore = useCalendarStore()
const { notificationsCount, cars, currentUser } = storeToRefs(calendarStore)

const refreshNotifications = async() => {
  const carsResponse = await getCarsInChargeNotUpdatedNotifications()
  const replacementsResponse = await getReplacementCarNoReturnedNotifications()
  replacementsNotification.value = replacementsResponse.data.length === 1 ? replacementsResponse.data[0] : null
  carsNotification.value = carsResponse.data.length === 1 ? carsResponse.data[0] : null
}

const handleClose = () => {
  if (calendarStore.notificationsModalOpen) calendarStore.notificationsModalOpen = false
  markNotificationsAsRead(carsNotification.value?.id, replacementsNotification.value?.id)
}

const onSelectCar = (car) => {
  handleClose()
  window.location.href = `${calendarStore.baseUrl}/calendrier/?date=${getIsoString(getCalendarDate({ car, type: "car" }))}&search=${car.id}`
}

watch(() => cars.value, () => {
  refreshNotifications()
}, { deep: true })

watch([replacementsNotification, carsNotification], () => {
  notificationsCount.value =
    (carsNotification.value?.cars?.length || 0) +
    (replacementsNotification.value?.replacements?.length || 0)
})

watch(() => currentUser.value, async() => {
  if (currentUser.value?.id) {
    await refreshNotifications()
    if ((carsNotification.value && !carsNotification.value.viewers.includes(currentUser.value.id)) ||
    (replacementsNotification.value && !replacementsNotification.value.viewers.includes(currentUser.value.id))) {
      calendarStore.notificationsModalOpen = true
    }
  }
}, { deep: true })
</script>

<template>
  <Dialog
    v-model:visible="calendarStore.notificationsModalOpen"
    :header="`Notifications${notificationsCount > 0 ? ` (${notificationsCount})` : ''}`"
    modal
    @after-hide="handleClose"
  >
    <div
      v-if="!carsNotification && !replacementsNotification"
      class="notifications-empty"
    >
      Aucune notification
    </div>
    <div
      v-else
      class="notifications"
    >
      <CarsNotUpdated
        v-if="carsNotification?.cars?.length > 0"
        :cars="orderBy(carsNotification.cars, 'start_work', 'desc')"
        :on-select-car="onSelectCar"
      />
      <ReplacementNotReturned
        v-if="replacementsNotification?.replacements?.length > 0"
        :replacements="replacementsNotification.replacements"
        :on-select-car="onSelectCar"
      />
      <button
        class="notification-read-button"
        @click="calendarStore.notificationsModalOpen = false"
      >
        Lu
      </button>
    </div>
  </Dialog>
</template>

<style lang="scss" scoped>
@import "../../../../../../ui/static_src/ui/base/mixins";

.notifications-empty {
  @include body;
  color: var(--color-text-secondary);
  text-align: left;
  width: 30rem;
}

.notifications {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.notification-read-button {
  @include body;
  color: var(--color-text-quaternary);
  background-color: var(--color-button-bg-submit);
  padding: .4rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  margin-top: 2rem;
}
</style>
