<script setup>
import { CalendarClock, CalendarPlus, EllipsisVertical, FilePenLine, FilePlus2, Pencil, X } from "lucide-vue-next"
import SpeedDial from "primevue/speeddial"
import { computed, ref } from "vue"

import useStaffStore from "../../stores/staff.js"

const props = defineProps({
  staff: {
    type: Object,
    required: true,
  },
  currentContract: {
    type: Object,
    required: false,
    default: () => null,
  },
  currentAbsence: {
    type: Object,
    required: false,
    default: () => null,
  },
  presence: {
    type: String,
    required: true,
  },
})

const staffStore = useStaffStore()
const isButtonToggled = ref(false)

const items = computed(() => ([
  props.presence === "Inactif" ? { label: "new-contract" } : { label: "update-current-contract" },
  props.presence === "Absent" ? { label: "update-current-absence" } : { label: "new-absence" },
  { label: "edit-staff" },
]))

const openStaffModal = () => {
  staffStore.openStaffModal(props.staff)
}

const openContractModal = () => {
  staffStore.openContractModal(props.staff, props.currentContract)
}

const openAbsenceModal = () => {
  staffStore.openAbsenceModal(props.staff, props.currentAbsence)
}
</script>

<template>
  <SpeedDial
    direction="left"
    :model="items"
    class="staff-speed-dial"
    @hide="isButtonToggled = false"
    @show="isButtonToggled = true"
  >
    <template #button="{ toggleCallback }">
      <button
        class="staff-speed-dial-button"
        @click="toggleCallback"
      >
        <transition
          name="fade"
          mode="out-in"
        >
          <EllipsisVertical v-if="!isButtonToggled" />
          <X v-else />
        </transition>
      </button>
    </template>
    <template #item="{ item }">
      <button
        v-if="item.label === 'new-contract'"
        v-tooltip.bottom="{ value: 'Nouveau contrat', showDelay: 500 }"
        class="staff-speed-dial-item-button"
        @click="openContractModal"
      >
        <FilePlus2 />
      </button>
      <button
        v-else-if="item.label === 'update-current-contract'"
        v-tooltip.bottom="{ value: 'Modifier le contrat en cours', showDelay: 500 }"
        class="staff-speed-dial-item-button"
        @click="openContractModal"
      >
        <FilePenLine />
      </button>
      <button
        v-else-if="item.label === 'new-absence'"
        v-tooltip.bottom="{ value: 'Nouvelle absence', showDelay: 500 }"
        class="staff-speed-dial-item-button"
        @click="openAbsenceModal"
      >
        <CalendarPlus />
      </button>
      <button
        v-else-if="item.label === 'update-current-absence'"
        v-tooltip.bottom="{ value: 'Modifier l\'absence en cours', showDelay: 500 }"
        class="staff-speed-dial-item-button"
        @click="openAbsenceModal"
      >
        <CalendarClock />
      </button>
      <button
        v-else
        v-tooltip.bottom="{ value: 'Modifier les informations du compagnon', showDelay: 500 }"
        class="staff-speed-dial-item-button"
        @click="openStaffModal"
      >
        <Pencil />
      </button>
    </template>
  </SpeedDial>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src/ui/base/mixins';

.staff-speed-dial {
  position: absolute;
}

.staff-speed-dial-button {
  @include body;
  padding: .5rem;
  border-radius: 50%;
  color: var(--color-text-secondary);

  &:hover {
    background-color: var(--color-button-bg-focus);
    color: var(--color-text-primary);
  }
}

.staff-speed-dial-item-button {
  @include body;
  padding: .5rem;
  border-radius: 50%;
  color: var(--color-text-secondary);
  background-color: var(--content-bg--floating-button);

  &:hover {
    color: var(--color-text-primary);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity var(--p-transition-duration) ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
