import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "document-file-box"
}
const _hoisted_2 = { class: "document-file" }
const _hoisted_3 = { class: "document-label" }
const _hoisted_4 = { class: "document-file-detail" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "document-file-text" }
const _hoisted_7 = { class: "document-file-text -secondary" }
const _hoisted_8 = {
  key: 0,
  class: "document-upload-progress-bar"
}
const _hoisted_9 = {
  key: 1,
  class: "document-file-detail -small-gap"
}
const _hoisted_10 = {
  key: 2,
  class: "document-file-detail -small-gap"
}
const _hoisted_11 = ["disabled"]
const _hoisted_12 = {
  key: 1,
  class: "file-input"
}
const _hoisted_13 = ["accept"]
const _hoisted_14 = { class: "document-file-label" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return ($props.file)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString($props.label), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_4, [
            ($props.file.absolute_path)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  class: "document-file-text",
                  href: $props.file.absolute_path,
                  target: "_blank"
                }, _toDisplayString($props.file.name), 9 /* TEXT, PROPS */, _hoisted_5))
              : (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  onClick: $setup.handleOpenDocumentPreview
                }, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString($props.file.name), 1 /* TEXT */)
                ])),
            _createElementVNode("div", _hoisted_7, _toDisplayString($setup.size), 1 /* TEXT */)
          ]),
          ($props.file.progression !== 100)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                ($props.file.progression > 0)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "document-upload-progress",
                      style: _normalizeStyle({ width: `${$props.file.progression}%` })
                    }, _toDisplayString($props.file.progression) + "% ", 5 /* TEXT, STYLE */))
                  : _createCommentVNode("v-if", true)
              ]))
            : (!$props.errorRaised)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode($setup["CircleCheck"], {
                    size: "14",
                    class: "document-file-success",
                    "stroke-width": 2.5
                  }),
                  _cache[1] || (_cache[1] = _createElementVNode("div", { class: "document-file-text -secondary -success" }, " Chargé ", -1 /* HOISTED */))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode($setup["X"], {
                    size: "14",
                    class: "document-file-failure",
                    "stroke-width": 2.5
                  }),
                  _cache[2] || (_cache[2] = _createElementVNode("div", { class: "document-file-text -secondary -failure" }, " Impossible d'extraire les données ", -1 /* HOISTED */))
                ]))
        ]),
        ($props.file.progression === 100)
          ? _withDirectives((_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "document-file-button",
              disabled: $props.file.progression !== 100,
              onClick: $setup.handleDeleteDocument
            }, [
              _createVNode($setup["Trash2"], {
                size: "18",
                class: "document-file-trash"
              })
            ], 8 /* PROPS */, _hoisted_11)), [
              [
                _directive_tooltip,
                { content: 'Supprimer le document', delay: 1000 },
                void 0,
                { left: true }
              ]
            ])
          : _createCommentVNode("v-if", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_12, [
        _createVNode($setup["Upload"], {
          size: "24",
          class: "file-input-icon"
        }),
        _createElementVNode("input", {
          class: "document-file-input",
          type: "file",
          name: "file",
          accept: $props.acceptedFormats,
          onChange: _cache[0] || (_cache[0] = $event => ($setup.emit('file-selected', $event)))
        }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_13),
        _createElementVNode("div", _hoisted_14, _toDisplayString($props.placeholder), 1 /* TEXT */)
      ]))
}