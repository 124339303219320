import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "staff-loading-data"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (!$setup.isListEmpty)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createVNode($setup["StaffToolbar"]),
        ($setup.staff.staffList.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
              _createElementVNode("i", { class: "pi pi-spin pi-cog loading-icon" }, null, -1 /* HOISTED */)
            ])))
          : (_openBlock(), _createBlock($setup["StaffTable"], { key: 1 }))
      ], 64 /* STABLE_FRAGMENT */))
    : (_openBlock(), _createBlock($setup["EmptyStaffList"], { key: 1 }))
}