<script setup>
import Dialog from "primevue/dialog"
import FloatLabel from "primevue/floatlabel"
import Select from "primevue/select"
import { ref, watch } from "vue"

import { getRepairingStateList } from "../../../../../companies/static_src/companies/utils/cars.js"

const emit = defineEmits(["update:modelValue"])

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  object: {
    type: Object,
    required: true,
  },
  car: {
    type: Object,
    required: true,
  },
  handleValidate: {
    type: Function,
    required: true,
  },
})

const localOpenModal = ref(props.modelValue)

watch(() => props.modelValue, (newVal) => {
  localOpenModal.value = newVal
})

watch(localOpenModal, (newVal) => {
  emit("update:modelValue", newVal)
})

const states = ["sheet_metal_state", "painting_state", "mechanic_state"]
const statesName = ["Tôlerie", "Peinture", "Mécanique"]
const isStateDisabled = (index) => {
  if (index === 0 && props.car.sheet_metal_work_hours + props.car.delayed_works.reduce((acc, work) => acc + work.sheet_metal_work_hours, 0) === 0) return true
  if (index === 1 && props.car.painting_hours + props.car.delayed_works.reduce((acc, work) => acc + work.painting_hours, 0) === 0) return true
  if (index === 2 && props.car.mechanic_hours + props.car.delayed_works.reduce((acc, work) => acc + work.mechanic_hours, 0) === 0) return true
  return false
}
const statesRef = ref([
  isStateDisabled(0) ? null : getRepairingStateList().find(s => s.value === props.car.sheet_metal_state).label,
  isStateDisabled(1) ? null : getRepairingStateList().find(s => s.value === props.car.painting_state).label,
  isStateDisabled(2) ? null : getRepairingStateList().find(s => s.value === props.car.mechanic_state).label,
])
</script>

<template>
  <Dialog
    v-model:visible="localOpenModal"
    header="État des réparations"
    modal
  >
    <div class="calendar-week-day-repairing-state-selecters">
      <FloatLabel
        v-for="(state, index) in states"
        :key="state"
        v-tooltip.bottom="isStateDisabled(index) ? 'Aucune heure de travail n\'est planifié' : ''"
        variant="on"
      >
        <Select
          v-model="statesRef[index]"
          :disabled="isStateDisabled(index)"
          :input-id="state"
          :options="getRepairingStateList().map(s => s.label)"
          class="repairing-state-selecter"
        />
        <label :for="state">{{ statesName[index] }}</label>
      </FloatLabel>
    </div>
    <div class="submit-container">
      <button
        class="submit"
        @click="handleValidate(statesRef)"
      >
        Enregistrer
      </button>
    </div>
  </Dialog>
</template>

<style lang="scss" scoped>
.calendar-week-day-repairing-state-selecters {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1rem;
  height: 4rem;
}

.repairing-state-selecter {
  min-width: 14rem;
}
</style>
