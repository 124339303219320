import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "notification" }
const _hoisted_2 = { class: "notification-list" }
const _hoisted_3 = { class: "notification-car-date" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "notification-car-state" }
const _hoisted_6 = { class: "notification-car-details" }
const _hoisted_7 = { class: "car__name" }
const _hoisted_8 = { class: "car__tow-truck" }
const _hoisted_9 = {
  key: 1,
  class: "car__tow-truck-icon"
}
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "car__replacement" }
const _hoisted_12 = {
  key: 0,
  class: "car__replacement-icon"
}
const _hoisted_13 = ["href"]
const _hoisted_14 = { class: "car-followup" }
const _hoisted_15 = {
  key: 0,
  class: "car-followup-point -complete"
}
const _hoisted_16 = {
  key: 1,
  class: "car-followup-point -incomplete"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "notification-title" }, " Des rendez-vous semblent ne pas avoir eu lieu ", -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.cars, (car) => {
        return (_openBlock(), _createElementBlock("div", {
          key: car.id,
          class: "notification-car-line"
        }, [
          _createElementVNode("div", _hoisted_3, _toDisplayString($setup.getFullDate(new Date(car.start_work))), 1 /* TEXT */),
          _createElementVNode("button", {
            class: "notification-car",
            onClick: $event => ($props.onSelectCar(car))
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: _normalizeClass(`car__card-state -${car.state}`)
              }, null, 2 /* CLASS */)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(car.owner_last_name) + " | " + _toDisplayString(car.model), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_8, [
                (car.is_seriously_damaged)
                  ? (_openBlock(), _createBlock($setup["Flame"], {
                      key: 0,
                      size: "18",
                      class: "car__seriously-damaged-icon"
                    }))
                  : (car.from_tow_truck)
                    ? (_openBlock(), _createElementBlock("svg", _hoisted_9, [
                        _createElementVNode("use", {
                          href: $setup.svgLink('cars','tow-truck')
                        }, null, 8 /* PROPS */, _hoisted_10)
                      ]))
                    : _createCommentVNode("v-if", true)
              ]),
              _createElementVNode("div", _hoisted_11, [
                (car.need_replacement)
                  ? (_openBlock(), _createElementBlock("svg", _hoisted_12, [
                      _createElementVNode("use", {
                        href: $setup.svgLink('cars','car-replacement')
                      }, null, 8 /* PROPS */, _hoisted_13)
                    ]))
                  : _createCommentVNode("v-if", true)
              ]),
              _createElementVNode("div", _hoisted_14, [
                (car.guarantee_given && car.repair_order && car.valid_support && car.expert_report_received)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15))
                  : (_openBlock(), _createElementBlock("div", _hoisted_16))
              ])
            ])
          ], 8 /* PROPS */, _hoisted_4)
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}