import { createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "car-incharge-form-block" }
const _hoisted_2 = { class: "car-form-subtitle" }
const _hoisted_3 = { class: "car-incharge-form-block -row -no-padding" }
const _hoisted_4 = { class: "car-incharge-form-block -row -no-padding" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["UserRound"], { size: "24" }),
      _cache[6] || (_cache[6] = _createTextVNode(" Propriétaire "))
    ]),
    _createElementVNode("div", _hoisted_3, [
      (!$setup.isCreation && !$props.modelValue.owner_first_name)
        ? (_openBlock(), _createBlock($setup["FloatLabel"], {
            key: 0,
            variant: "on"
          }, {
            default: _withCtx(() => [
              _createVNode($setup["InputText"], {
                id: "owner_first_name",
                name: "owner_first_name",
                "model-value": $props.modelValue.owner_first_name,
                style: {"min-width":"12.5rem"},
                "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => $setup.updateModel('owner_first_name', value))
              }, null, 8 /* PROPS */, ["model-value"]),
              _cache[7] || (_cache[7] = _createElementVNode("label", { for: "owner_first_name" }, "Prénom", -1 /* HOISTED */))
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _createVNode($setup["FloatLabel"], {
        variant: "on",
        style: _normalizeStyle($setup.isCreation ? 'width: 100%;' : '')
      }, {
        default: _withCtx(() => [
          _createVNode($setup["InputText"], {
            id: "owner_last_name",
            name: "owner_last_name",
            "model-value": $props.modelValue.owner_last_name,
            style: _normalizeStyle($setup.isCreation ? 'width: 100%;' : 'min-width: 12.5rem;'),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = (value) => $setup.updateModel('owner_last_name', value))
          }, null, 8 /* PROPS */, ["model-value", "style"]),
          _cache[8] || (_cache[8] = _createElementVNode("label", { for: "owner_last_name" }, "Nom", -1 /* HOISTED */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["style"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode($setup["FloatLabel"], { variant: "on" }, {
        default: _withCtx(() => [
          _createVNode($setup["InputText"], {
            id: "owner_phone_number",
            name: "owner_phone_number",
            "model-value": $props.modelValue.owner_phone_number,
            style: {"min-width":"12.5rem"},
            "onUpdate:modelValue": _cache[2] || (_cache[2] = (value) => $setup.updateModel('owner_phone_number', value))
          }, null, 8 /* PROPS */, ["model-value"]),
          _cache[9] || (_cache[9] = _createElementVNode("label", { for: "owner_phone_number" }, "Numéro de téléphone 1", -1 /* HOISTED */))
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode($setup["FloatLabel"], { variant: "on" }, {
        default: _withCtx(() => [
          _createVNode($setup["InputText"], {
            id: "owner_phone_number_2",
            name: "owner_phone_number_2",
            "model-value": $props.modelValue.owner_phone_number_2,
            style: {"min-width":"12.5rem"},
            "onUpdate:modelValue": _cache[3] || (_cache[3] = (value) => $setup.updateModel('owner_phone_number_2', value))
          }, null, 8 /* PROPS */, ["model-value"]),
          _cache[10] || (_cache[10] = _createElementVNode("label", { for: "owner_phone_number_2" }, "Numéro de téléphone 2", -1 /* HOISTED */))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    (!$setup.isCreation || $props.modelValue.owner_email)
      ? (_openBlock(), _createBlock($setup["FloatLabel"], {
          key: 0,
          variant: "on"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["InputText"], {
              id: "owner_email",
              name: "owner_email",
              "model-value": $props.modelValue.owner_email,
              style: {"width":"100%"},
              "onUpdate:modelValue": _cache[4] || (_cache[4] = (value) => $setup.updateModel('owner_email', value))
            }, null, 8 /* PROPS */, ["model-value"]),
            _cache[11] || (_cache[11] = _createElementVNode("label", { for: "owner_phone_number_2" }, "Email", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    (!$setup.isCreation || $props.modelValue.owner_address)
      ? (_openBlock(), _createBlock($setup["FloatLabel"], {
          key: 1,
          variant: "on"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["InputText"], {
              id: "owner_address",
              name: "owner_address",
              "model-value": $props.modelValue.owner_address,
              style: {"width":"100%"},
              "onUpdate:modelValue": _cache[5] || (_cache[5] = (value) => $setup.updateModel('owner_address', value))
            }, null, 8 /* PROPS */, ["model-value"]),
            _cache[12] || (_cache[12] = _createElementVNode("label", { for: "owner_address" }, "Adresse", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ]))
}