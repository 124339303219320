<script setup>
import { orderBy } from "lodash-es"
import { Calendar, File, FileStack } from "lucide-vue-next"
import { storeToRefs } from "pinia"
import Tag from "primevue/tag"
import { computed } from "vue"

import AbsenceTimeline from "./_partials/AbsenceTimeline.vue"
import ContractTimeline from "./_partials/ContractTimeline.vue"

import useStaffStore from "../../stores/staff.js"
import { getContractWeekDays, getCurrentAbsence, getPresenceSeverity, isContractStaffActive } from "../../utils/staff.js"

const staff = useStaffStore()
const { staffObject } = storeToRefs(staff)

const domainNames = {
  "sheet-metal-worker": "Tôlier",
  painter: "Peintre",
  mechanic: "Mécanicien",
}

const rankNames = {
  senior: "Senior",
  apprentice: "Apprenti",
}

const isPresent = computed(() => {
  const currentContract = staffObject.value.contract_set && orderBy(staffObject.value.contract_set, "start_date", "desc")[0]
  const currentAbsence = getCurrentAbsence(staffObject.value)
  const isContractActive = currentContract && isContractStaffActive(currentContract)
  return isContractActive ? (currentAbsence ? "Absent" : "Présent") : "Inactif"
})

const weekDays = computed(() => {
  const currentContract = staffObject.value.contract_set && orderBy(staffObject.value.contract_set, "start_date", "desc")[0]
  return getContractWeekDays(currentContract)
})
</script>

<template>
  <div class="staff-detail-container">
    <div class="staff-detail">
      <div class="staff-detail-header">
        <div class="staff-detail-name">
          {{ staffObject.first_name }} {{ staffObject.last_name }} <span>{{ domainNames[staffObject.domain] }} {{ rankNames[staffObject.rank] }}</span>
        </div>
        <Tag
          :severity="getPresenceSeverity(isPresent)"
          :value="isPresent"
        />
      </div>
      <div class="staff-detail-week-days">
        <div
          v-for="(day, index) in weekDays"
          :key="index"
          :class="[
            'staff-week-day-half-day',
            { '-morning': day.haldDays.morning},
            { '-afternoon': day.haldDays.afternoon},
          ]"
        >
          {{ day.name }}
        </div>
      </div>
      <div class="staff-timeline-container">
        <div
          v-if="staffObject.contract_set.length > 1"
          class="staff-timeline-header"
        >
          <FileStack size="20" />
          Contrats
        </div>
        <div
          v-else-if="staffObject.contract_set.length === 1"
          class="staff-timeline-header"
        >
          <File size="20" />
          Contrat
        </div>
        <ContractTimeline />
      </div>
      <div
        v-if="staffObject.absence_set.length > 0"
        class="staff-timeline-container"
      >
        <div class="staff-timeline-header">
          <Calendar size="20" />
          Absence{{ staffObject.absence_set.length > 1 ? "s" : "" }}
        </div>
        <AbsenceTimeline />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src/ui/base/mixins';

.staff-detail-container {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  overflow-y: auto;
}

.staff-detail {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 60%;
  margin: 0 auto;
}

.staff-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.staff-detail-name {
  display: flex;
  @include title;
  gap: 1rem;

  span {
    @include title;
    color: var(--color-text-secondary);
  }
}

.staff-detail-week-days {
  position: relative;
  display: flex;
  gap: .4rem;
}

.staff-week-day-half-day {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  @include body;
  color: var(--color-text-quaternary);

  &.-morning {
    background: linear-gradient(to right, var(--color-text-submit-focus) 50%, var(--content-bg--color-dark) 50%);
  }

  &.-afternoon {
    background: linear-gradient(to left, var(--color-text-submit-focus) 50%, var(--content-bg--color-dark) 50%);
  }

  &.-morning.-afternoon {
    background: var(--color-text-submit-focus);
  }

  &:not(.-morning):not(.-afternoon) {
    background: var(--content-bg--color-dark);
  }
}

.staff-timeline-header {
  @include subtitle;
  display: flex;
  align-items: start;
  line-height: 1.6rem;
  gap: .4rem;
  margin-top: .4rem;
  margin-bottom: 1rem;
}
</style>
