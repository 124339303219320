<script setup>
import { asyncComputed } from "@vueuse/core/index.cjs"
import { computed, h } from "vue"
import { renderToString } from "vue/server-renderer"

import ReplacementCar from "../../../../../cars/static_src/cars/components/tooltips/ReplacementCar.vue"

import { svgLink } from "../../../../../ui/static_src/ui/utils/svg.js"
import { useCalendarStore } from "../../stores/calendar.js"
import { useCarStore } from "../../stores/car.js"

const props = defineProps({
  object: {
    type: Object,
    required: true,
  },
})

const calendarStore = useCalendarStore()
const carStore = useCarStore()

const isReplacementFinished = computed(() => {
  const car = props.object.type === "car" ? props.object.car : props.object.work.car
  if (car.replacements.length === 0) {
    return false
  }
  return car.replacements[0].finished && new Date(car.replacements[0].end_date).getTime() < new Date().getTime()
})

const isReplacementAffected = computed(() => {
  const car = props.object.type === "car" ? props.object.car : props.object.work.car
  return car.replacements.length > 0
})

const replacementTooltipOptions = asyncComputed(async() => {
  const car = props.object.type === "car" ? props.object.car : props.object.work.car
  const htmlContent = await renderToString(h(ReplacementCar, {
    replacement: car.replacements.length === 0 ? null : car.replacements[0],
  }))
  return { escape: false, value: htmlContent }
})

const openReplacementDialog = () => {
  calendarStore.replacementModalOpen = true
  carStore.focusedCar = props.object.type === "car" ? props.object.car : props.object.work.car
}
</script>

<template>
  <button
    v-tooltip.left="replacementTooltipOptions"
    class="replacement-car-button"
    @click="openReplacementDialog"
  >
    <svg :class="['replacement-car-icon', {'-finished': isReplacementFinished, '-affected': isReplacementAffected && !isReplacementFinished }]">
      <use :href="svgLink('cars', 'car-replacement')" />
    </svg>
  </button>
</template>

<style lang="scss" scoped>
.replacement-car-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 0;
  padding-block: 0;

  &:hover {
    background-color: transparent;
    transform: scale(1.1);
  }
}

.replacement-car-icon {
  width: 1.2rem;
  height: 1.2rem;
  color: var(--color-text-submit);

  &.-finished {
    color: var(--color-text-success);
  }

  &.-affected {
    color: var(--color-text-feedback);
  }
}
</style>
