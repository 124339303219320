<script setup>
import { ChevronDown, Folder } from "lucide-vue-next"
import Checkbox from "primevue/checkbox"
import DatePicker from "primevue/datepicker"
import FloatLabel from "primevue/floatlabel"
import { computed, ref } from "vue"

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
})

const isDropOff = ref(!props.modelValue.id)
const emit = defineEmits(["update:modelValue"])

const checkboxFields = computed(() => (
  isDropOff.value
    ? {}
    : {
        expert_report_received: "Rapport d'expert reçu",
        guarantee_given: "Chèque de garantie donné",
        repair_order: "Ordre de réparation prêt",
        valid_support: "Prise en charge valide",
        from_tow_truck: "Arrivé par dépannage",
        is_seriously_damaged: "Véhicule gravement endommagé",
      }),
)

const dropDown = (event) => {
  event.preventDefault()
  event.stopPropagation()
  isDropOff.value = !isDropOff.value
}

const updateModel = (key, value, event = null) => {
  if (event) {
    event.preventDefault()
    event.stopPropagation()
  }

  emit("update:modelValue", {
    ...props.modelValue,
    [key]: value,
  })
}
</script>

<template>
  <div :class="['car-incharge-form-block', { '-no-gap': isDropOff }]">
    <div class="car-form-subtitle">
      <Folder size="24" />
      Dossier
      <button
        class="car-form-dropdown"
        @click="dropDown"
      >
        <ChevronDown
          :class="['car-form-subtitle-chevron', { '-rotate': !isDropOff }]"
          size="24"
        />
      </button>
    </div>
    <TransitionGroup
      name="list"
      tag="div"
      class="car-incharge-form-block -no-padding"
    >
      <button
        v-for="(field, key) in checkboxFields"
        :key="key"
        :class="['car-form-checkbox', { '-hide': isDropOff }]"
        @click="updateModel(key, !modelValue[key], $event)"
      >
        <Checkbox
          :model-value="modelValue[key]"
          :input-id="key"
          :name="key"
          :binary="true"
          class="checkbox-car-incharge"
        />
        <label
          :for="key"
          style="cursor: pointer; color: var(--color-text-secondary);"
        >{{ field }}</label>
      </button>
      <FloatLabel
        v-if="!isDropOff"
        variant="on"
        :class="end-valid-support-date"
      >
        <DatePicker
          id="end_valid_support_date"
          name="end_valid_support_date"
          :model-value="modelValue.end_valid_support_date"
          style="width: 100%;"
          date-format="dd/mm/yy"
          @update:model-value="(value) => updateModel('end_valid_support_date', value)"
        />
        <label for="end_valid_support_date">Date de fin de validité de la prise en charge</label>
      </FloatLabel>
    </TransitionGroup>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../../../../ui/static_src/ui/base/mixins';

.car-incharge-form-block {
  display: flex;
  flex-direction: column;
  padding: .4rem 0rem;
  gap: 1rem;
  width: 100%;

  &.-row {
    flex-direction: row;
    justify-content: space-between;
  }

  &.-no-padding {
    padding: 0;
  }

  &.-no-gap {
    gap: 0;
  }
}

.car-form-subtitle {
  @include subtitle;
  display: flex;
  align-items: center;
  gap: .4rem;
  margin-top: .4rem;
}

.car-form-dropdown {
  color: var(--color-text-secondary);

  &:hover {
    color: var(--color-text-primary);
  }
}

.car-form-subtitle-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.car-form-checkbox {
  display: flex;
  align-items: center;
  gap: .6rem;
  width: fit-content;
  padding-inline: 0;
  padding-block: 0;
  opacity: 1;

  &:hover {
    background-color: var(--content-bg--color-lighter);
  }

  &.-hide {
    display: none;
  }
}

.end-valid-support-date {
  transform: translateY(1.2rem);
  margin-bottom: .8rem;
}

.car-form-subtitle-chevron {
  transition: transform var(--p-transition-duration);

  &.-rotate {
    transform: rotate(-180deg);
  }
}

.list-enter-active{
  transition: all var(--p-transition-duration) ease;
}

.list-leave-active {
  transition: all 0s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
