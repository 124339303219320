import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "calendar-week-day-repairing-state-selecters" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "submit-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createBlock($setup["Dialog"], {
    visible: $setup.localOpenModal,
    "onUpdate:visible": _cache[1] || (_cache[1] = $event => (($setup.localOpenModal) = $event)),
    header: "État des réparations",
    modal: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.states, (state, index) => {
          return _withDirectives(_createVNode($setup["FloatLabel"], {
            key: state,
            variant: "on"
          }, {
            default: _withCtx(() => [
              _createVNode($setup["Select"], {
                modelValue: $setup.statesRef[index],
                "onUpdate:modelValue": $event => (($setup.statesRef[index]) = $event),
                disabled: $setup.isStateDisabled(index),
                "input-id": state,
                options: $setup.getRepairingStateList().map(s => s.label),
                class: "repairing-state-selecter"
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled", "input-id", "options"]),
              _createElementVNode("label", { for: state }, _toDisplayString($setup.statesName[index]), 9 /* TEXT, PROPS */, _hoisted_2)
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */), [
            [
              _directive_tooltip,
              $setup.isStateDisabled(index) ? 'Aucune heure de travail n\'est planifié' : '',
              void 0,
              { bottom: true }
            ]
          ])
        }), 64 /* STABLE_FRAGMENT */))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "submit",
          onClick: _cache[0] || (_cache[0] = $event => ($props.handleValidate($setup.statesRef)))
        }, " Enregistrer ")
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible"]))
}