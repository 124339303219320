import { createPinia } from "pinia"
import PrimeVue from "primevue/config"
import ToastService from "primevue/toastservice"
import Tooltip from "primevue/tooltip"
import { createApp } from "vue"

import router from "./router/index.js"
import CalendarApp from "./views/CalendarApp.vue"
import StaffApp from "./views/StaffApp.vue"

const pinia = createPinia()

// Calendar App
const calendarApp = createApp(CalendarApp)

calendarApp.use(pinia)
calendarApp.use(ToastService)
calendarApp.use(PrimeVue, {
  locale: {
    firstDayOfWeek: 1,
    dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
    dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
    monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    monthNamesShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Jun", "Jul", "Aoû", "Sep", "Oct", "Nov", "Déc"],
  },
})
calendarApp.directive("tooltip", Tooltip)
calendarApp.mount("#calendar-app")

// Staff App
const staffApp = createApp(StaffApp)

staffApp.use(router)
staffApp.use(pinia)
staffApp.use(PrimeVue, {
  locale: {
    firstDayOfWeek: 1,
    dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
    dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
    monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    monthNamesShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Jun", "Jul", "Aoû", "Sep", "Oct", "Nov", "Déc"],
  },
})
staffApp.use(ToastService)
staffApp.directive("tooltip", Tooltip)
staffApp.mount("#staff-app")
