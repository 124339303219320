<script setup>
import { storeToRefs } from "pinia"
import { useToast } from "primevue"
import Dialog from "primevue/dialog"
import { ref } from "vue"

import CarInChargeFields from "../../../../../../cars/static_src/cars/components/_partials/CarInCharge.vue"
import InsurancesSelecter from "../InsurancesSelecter.vue"

import { createRegistrationCar } from "../../../services/api.js"
import { useCalendarStore } from "../../../stores/calendar.js"
import { useCarStore } from "../../../stores/car.js"
import { getStateDate } from "../../../utils/cars.js"

const calendarStore = useCalendarStore()
const carStore = useCarStore()
const toast = useToast()

const { focusedCar } = storeToRefs(carStore)
const registrationCarFile = ref(null)
const loading = ref(false)

const handleRegistrationCarCreation = (newCar) => {
  if (registrationCarFile.value) {
    const formData = new FormData()
    formData.append("file", registrationCarFile.value.file)
    formData.append("original_filename", registrationCarFile.value.original_filename)
    formData.append("car_incharge_id", newCar.id)
    formData.append("size", registrationCarFile.value.size)
    createRegistrationCar(formData).then(({ data }) => {
      calendarStore.cars.find((car) => car.id === newCar.id).registration_car = data
    })
  }
}

const handleValidate = () => {
  if (getStateDate(focusedCar.value) === null || !focusedCar.value.model || !focusedCar.value.owner_last_name || loading.value) return
  loading.value = true
  if (carStore.updatingCar) {
    if (!focusedCar.value.need_replacement && focusedCar.value.replacements.length > 0) {
      calendarStore.deleteReplacement(focusedCar.value.replacements[0])
      toast.add({ severity: "success", summary: "Remplacement supprimé" })
    }
    calendarStore.updateCarInCharge(focusedCar.value).then((car) => {
      handleRegistrationCarCreation(car)
      carStore.carInChargeModalOpen = false
      loading.value = false
    })
  } else {
    calendarStore.createCarInCharge(focusedCar.value).then((car) => {
      handleRegistrationCarCreation(car)
      carStore.carInChargeModalOpen = false
      loading.value = false
    })
  }
}

const handleRegistrationCarExtractedData = (data) => {
  focusedCar.value.registration = data.car_registration
  focusedCar.value.brand = data.car_brand
  focusedCar.value.model = data.car_model
  focusedCar.value.first_registration_date = new Date(data.first_date_registration)
  focusedCar.value.owner_first_name = data.owner_first_name
  focusedCar.value.owner_last_name = data.owner_last_name
  focusedCar.value.owner_address = data.owner_address

  registrationCarFile.value = { file: data.file, original_filename: data.filename, size: data.size }
}
</script>

<template>
  <Dialog
    v-model:visible="carStore.carInChargeModalOpen"
    :header="focusedCar.id ? 'Modification du véhicule en charge' : 'Nouveau véhicule en charge'"
    modal
  >
    <CarInChargeFields
      v-model="focusedCar"
      :handle-validate="handleValidate"
      :loading="loading"
      @registration-car-data-extracted="handleRegistrationCarExtractedData"
    >
      <template #default="slotProps">
        <InsurancesSelecter :form="slotProps.form" />
      </template>
    </CarInChargeFields>
  </Dialog>
</template>
