import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "car-incharge-form-block" }
const _hoisted_2 = { class: "car-incharge-form-block -row -no-padding" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["FloatLabel"], { variant: "on" }, {
      default: _withCtx(() => [
        _createVNode($setup["InputText"], {
          id: "registration",
          name: "registration",
          "model-value": $props.modelValue.registration,
          style: {"width":"100%"},
          "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => $setup.updateModel('registration', value))
        }, null, 8 /* PROPS */, ["model-value"]),
        _cache[5] || (_cache[5] = _createElementVNode("label", { for: "registration" }, "Plaque d'immatriculation", -1 /* HOISTED */))
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["FloatLabel"], { variant: "on" }, {
        default: _withCtx(() => [
          _createVNode($setup["InputText"], {
            id: "brand",
            name: "brand",
            "model-value": $props.modelValue.brand,
            style: {"min-width":"12.5rem"},
            "onUpdate:modelValue": _cache[1] || (_cache[1] = (value) => $setup.updateModel('brand', value))
          }, null, 8 /* PROPS */, ["model-value"]),
          _cache[6] || (_cache[6] = _createElementVNode("label", { for: "brand" }, "Marque", -1 /* HOISTED */))
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode($setup["FloatLabel"], { variant: "on" }, {
        default: _withCtx(() => [
          _createVNode($setup["InputText"], {
            id: "model",
            name: "model",
            "model-value": $props.modelValue.model,
            style: {"min-width":"12.5rem"},
            "onUpdate:modelValue": _cache[2] || (_cache[2] = (value) => $setup.updateModel('model', value))
          }, null, 8 /* PROPS */, ["model-value"]),
          _cache[7] || (_cache[7] = _createElementVNode("label", { for: "model" }, "Modèle", -1 /* HOISTED */))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    (!$setup.isCreation || $props.modelValue.first_registration_date)
      ? (_openBlock(), _createBlock($setup["FloatLabel"], {
          key: 0,
          variant: "on"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["DatePicker"], {
              id: "first_registration_date",
              name: "first_registration_date",
              "model-value": $props.modelValue.first_registration_date,
              style: {"width":"100%"},
              "date-format": "dd/mm/yy",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = (value) => $setup.updateModel('first_registration_date', value))
            }, null, 8 /* PROPS */, ["model-value"]),
            _cache[8] || (_cache[8] = _createElementVNode("label", { for: "first_registration_date" }, "Date de 1ère immatriculation", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["FloatLabel"], { variant: "on" }, {
      default: _withCtx(() => [
        _createVNode($setup["InputText"], {
          id: "foreground_note",
          name: "foreground_note",
          "model-value": $props.modelValue.foreground_note,
          style: {"width":"100%"},
          "onUpdate:modelValue": _cache[4] || (_cache[4] = (value) => $setup.updateModel('foreground_note', value))
        }, null, 8 /* PROPS */, ["model-value"]),
        _cache[9] || (_cache[9] = _createElementVNode("label", { for: "foreground_note" }, "Note", -1 /* HOISTED */))
      ]),
      _: 1 /* STABLE */
    })
  ]))
}