<script setup>
import { onMounted } from "vue"
import { useRoute } from "vue-router"

import StaffDetail from "../components/staff/StaffDetail.vue"
import StaffDetailToolbar from "../components/staff/StaffDetailToolbar.vue"

import { getStaff } from "../services/api.js"
import useStaffStore from "../stores/staff.js"

const route = useRoute()
const staff = useStaffStore()

onMounted(async() => {
  const staffID = route.params.staffSlug_staffID.split("_")[1]
  const { data } = await getStaff(staffID)
  staff.staffObject = data
})
</script>

<template>
  <StaffDetailToolbar v-if="staff.staffObject" />
  <StaffDetail v-if="staff.staffObject" />
  <div
    v-else
    class="staff-loading-data"
  >
    <i class="pi pi-spin pi-cog loading-icon" />
  </div>
</template>

<style lang="scss" scoped>
@import "../../../../ui/static_src/ui/base/mixins";

.staff-loading-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loading-icon {
  color: var(--color-text-primary);
  font-size: 2rem;
}
</style>
