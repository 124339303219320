import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "staff-form-block" }
const _hoisted_2 = { class: "staff-form-block -margin-top" }
const _hoisted_3 = { class: "staff-form-validate" }
const _hoisted_4 = {
  key: 0,
  class: "pi pi-spin pi-cog loading-icon"
}
const _hoisted_5 = {
  key: 1,
  class: "staff-form-button",
  type: "submit"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["Dialog"], {
    visible: $setup.isStaffModalOpen,
    "onUpdate:visible": _cache[4] || (_cache[4] = $event => (($setup.isStaffModalOpen) = $event)),
    header: $setup.focusedStaff?.id ? 'Modifier le compagnon' : 'Ajouter un compagnon',
    modal: ""
  }, {
    default: _withCtx(() => [
      _createVNode($setup["Form"], {
        resolver: $setup.resolver,
        onSubmit: $setup.onFormSubmit
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode($setup["FloatLabel"], { variant: "on" }, {
              default: _withCtx(() => [
                _createVNode($setup["InputText"], {
                  id: "first_name",
                  name: "first_name",
                  "model-value": $setup.focusedStaff.first_name,
                  style: {"min-width":"12.5rem"},
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => $setup.updateModel('first_name', value))
                }, null, 8 /* PROPS */, ["model-value"]),
                _cache[5] || (_cache[5] = _createElementVNode("label", { for: "first_name" }, "Prénom", -1 /* HOISTED */))
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode($setup["FloatLabel"], { variant: "on" }, {
              default: _withCtx(() => [
                _createVNode($setup["InputText"], {
                  id: "last_name",
                  name: "last_name",
                  "model-value": $setup.focusedStaff.last_name,
                  style: {"min-width":"12.5rem"},
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = (value) => $setup.updateModel('last_name', value))
                }, null, 8 /* PROPS */, ["model-value"]),
                _cache[6] || (_cache[6] = _createElementVNode("label", { for: "last_name" }, "Nom", -1 /* HOISTED */))
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode($setup["FloatLabel"], { variant: "on" }, {
              default: _withCtx(() => [
                _createVNode($setup["Select"], {
                  id: "domain",
                  name: "domain",
                  "model-value": $setup.staffDomainNames[$setup.focusedStaff.domain],
                  options: ['Tôlier', 'Peintre', 'Mécanicien'],
                  style: {"width":"12.5rem"},
                  onValueChange: _cache[2] || (_cache[2] = (value) => $setup.updateModel('domain', $setup.staffDomainValues[value]))
                }, null, 8 /* PROPS */, ["model-value"]),
                _cache[7] || (_cache[7] = _createElementVNode("label", { for: "state" }, "Domaine", -1 /* HOISTED */))
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode($setup["FloatLabel"], { variant: "on" }, {
              default: _withCtx(() => [
                _createVNode($setup["Select"], {
                  id: "rank",
                  name: "rank",
                  "model-value": $setup.staffRankNames[$setup.focusedStaff.rank],
                  options: ['Senior', 'Apprenti'],
                  style: {"width":"12.5rem"},
                  onValueChange: _cache[3] || (_cache[3] = (value) => $setup.updateModel('rank', $setup.staffRankValues[value]))
                }, null, 8 /* PROPS */, ["model-value"]),
                _cache[8] || (_cache[8] = _createElementVNode("label", { for: "state" }, "Expérience", -1 /* HOISTED */))
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            ($setup.loading)
              ? (_openBlock(), _createElementBlock("i", _hoisted_4))
              : (_openBlock(), _createElementBlock("button", _hoisted_5, _toDisplayString($setup.focusedStaff?.id ? 'Modifier' : 'Ajouter'), 1 /* TEXT */))
          ])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible", "header"]))
}