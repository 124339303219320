import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "car-incharge-form-block" }
const _hoisted_2 = { class: "car-form-subtitle" }
const _hoisted_3 = { class: "car-form-subtitle-icon" }
const _hoisted_4 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock("svg", _hoisted_3, [
        _createElementVNode("use", {
          href: $setup.svgLink('cars', 'car-replacement')
        }, null, 8 /* PROPS */, _hoisted_4)
      ])),
      _cache[1] || (_cache[1] = _createTextVNode(" Remplacement "))
    ]),
    _createElementVNode("button", {
      class: "car-form-checkbox",
      onClick: _cache[0] || (_cache[0] = $event => ($setup.updateModel('need_replacement', !$props.modelValue.need_replacement, $event)))
    }, [
      _createVNode($setup["Checkbox"], {
        "model-value": $props.modelValue.need_replacement,
        "input-id": "need_replacement",
        name: "need_replacement",
        binary: true,
        class: "checkbox-car-incharge"
      }, null, 8 /* PROPS */, ["model-value"]),
      _cache[2] || (_cache[2] = _createElementVNode("label", {
        for: "need_replacement",
        style: {"cursor":"pointer","color":"var(--color-text-secondary)"}
      }, "Le propriétaire souhaite un véhicule de remplacement", -1 /* HOISTED */))
    ])
  ]))
}