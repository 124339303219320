export const getPresenceSeverity = (presence) => {
  if (presence === "Absent") return "danger"
  if (presence === "Inactif") return "warn"
  return "success"
}

export const getContractWeekDays = (contract) => {
  if (!contract) {
    return [
      { name: "L", haldDays: { morning: false, afternoon: false } },
      { name: "M", haldDays: { morning: false, afternoon: false } },
      { name: "M", haldDays: { morning: false, afternoon: false } },
      { name: "J", haldDays: { morning: false, afternoon: false } },
      { name: "V", haldDays: { morning: false, afternoon: false } },
    ]
  }
  return [
    { name: "L", haldDays: contract.week_days.monday },
    { name: "M", haldDays: contract.week_days.tuesday },
    { name: "M", haldDays: contract.week_days.wednesday },
    { name: "J", haldDays: contract.week_days.thursday },
    { name: "V", haldDays: contract.week_days.friday },
  ]
}

export const isContractStaffActive = (contract) => {
  const now = new Date()
  return new Date(contract.start_date) <= now && (new Date(contract.end_date) >= now || !contract.end_date)
}

export const getCurrentAbsence = (staff) => {
  const now = new Date()
  return staff.absence_set.find((absence) => {
    const startDate = new Date(absence.start_date)
    const endDate = new Date(absence.end_date)
    if (absence.start_at === "afternoon") startDate.setHours(12)
    if (absence.end_at === "morning") endDate.setHours(12)
    else endDate.setHours(23, 59, 59)
    return startDate.getTime() <= now.getTime() && endDate.getTime() >= now.getTime()
  })
}
