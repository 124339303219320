<script setup>
import { ArrowRight, CalendarPlus, FilePlus2, UserPen } from "lucide-vue-next"
import { storeToRefs } from "pinia"
import { useRoute } from "vue-router"

import useStaffStore from "../../stores/staff.js"

const route = useRoute()
const staff = useStaffStore()
const { staffObject } = storeToRefs(staff)
</script>

<template>
  <div class="staff-toolbar">
    <a
      type="button"
      class="body__toolbar-button-menu -persistent"
      :href="`/${route.params.companySlug}/compagnons/`"
    >
      <ArrowRight class="car__go-back-icon" />
    </a>
    <button
      v-tooltip.left="'Modifier les informations du compagnon'"
      class="-highlighted staff-button"
      @click="staff.openStaffModal(staffObject)"
    >
      <UserPen />
    </button>
    <button
      v-tooltip.left="'Ajouter une absence'"
      class="-highlighted staff-button"
      @click="staff.openAbsenceModal(staffObject)"
    >
      <CalendarPlus />
    </button>
    <button
      v-tooltip.left="'Ajouter un contrat'"
      class="-highlighted staff-button"
      @click="staff.openContractModal(staffObject)"
    >
      <FilePlus2 />
    </button>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src/ui/base/mixins';

.staff-toolbar {
  display: flex;
  padding: 1rem;
  border-bottom: 0.4px solid var(--color-border-primary);
  justify-content: flex-start;
  gap: 1rem;
}

.staff-button {
  @include body;
  color: var(--color-text-quaternary);
  padding: .5rem;
}
</style>
