import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "calendar-week-header" }
const _hoisted_2 = { class: "calendar-week-details" }
const _hoisted_3 = { class: "calendar-week-dates" }
const _hoisted_4 = { class: "calendar-week-tools" }
const _hoisted_5 = { class: "calendar-week-replacement-count" }
const _hoisted_6 = { class: "calendar-week-tools-icon" }
const _hoisted_7 = ["href"]
const _hoisted_8 = {
  key: 0,
  class: "calendar-week-status"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString($setup.getFullDate($setup.props.monday)) + " - " + _toDisplayString($setup.getFullDate($setup.friday)), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(), _createElementBlock("svg", _hoisted_6, [
            _createElementVNode("use", {
              href: $setup.svgLink('cars', 'car-replacement')
            }, null, 8 /* PROPS */, _hoisted_7)
          ])),
          _createTextVNode(" " + _toDisplayString($setup.carsNeedReplacementCount + $setup.delayedWorksNeedReplacementCount) + " / " + _toDisplayString($setup.props.replacementCapacity), 1 /* TEXT */)
        ])), [
          [
            _directive_tooltip,
            'Véhicule emprunté cette semaine',
            void 0,
            { bottom: true }
          ]
        ]),
        _withDirectives((_openBlock(), _createElementBlock("button", {
          class: "calendar-week-tools-button",
          onClick: $setup.createNote
        }, [
          _createVNode($setup["MessageSquarePlus"], { size: "24" })
        ])), [
          [
            _directive_tooltip,
            { value: 'Ajouter une note', showDelay: 1000 },
            void 0,
            { bottom: true }
          ]
        ])
      ])
    ]),
    _createVNode($setup["CalendarWeekChargeChart"], {
      cars: $props.cars,
      "delayed-works": $props.delayedWorks,
      "week-capacity": $props.weekCapacity,
      "delay-animation": $setup.props.delayAnimation
    }, null, 8 /* PROPS */, ["cars", "delayed-works", "week-capacity", "delay-animation"]),
    _createVNode($setup["CalendarWeekDoneWorkChart"], {
      cars: $props.cars,
      "delayed-works": $props.delayedWorks,
      "week-capacity": $props.weekCapacity,
      "delay-animation": $setup.props.delayAnimation
    }, null, 8 /* PROPS */, ["cars", "delayed-works", "week-capacity", "delay-animation"]),
    (!$setup.calendarStore.isHomepage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (!$setup.calendarStore.filteredWeeks.includes($setup.getIsoString($setup.props.monday)))
            ? _withDirectives((_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "calendar-week-tools-button",
                onClick: _cache[0] || (_cache[0] = $event => ($setup.calendarStore.filterFolderOfWeek($setup.props.monday)))
              }, [
                _createVNode($setup["Filter"])
              ])), [
                [
                  _directive_tooltip,
                  { value: 'Afficher uniquement les rendez-vous avec un dossier incomplet', showDelay: 500 },
                  void 0,
                  { bottom: true }
                ]
              ])
            : _withDirectives((_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "calendar-week-tools-button",
                onClick: _cache[1] || (_cache[1] = $event => ($setup.calendarStore.unfilterFolderOfWeek($setup.props.monday)))
              }, [
                _createVNode($setup["FilterX"])
              ])), [
                [
                  _directive_tooltip,
                  { value: 'Afficher tous les rendez-vous de la semaine', showDelay: 500 },
                  void 0,
                  { bottom: true }
                ]
              ]),
          ($setup.calendarStore.isFullWeek($setup.props.monday))
            ? (_openBlock(), _createBlock($setup["SplitButton"], {
                key: 2,
                class: "-full",
                label: "COMPLET",
                icon: "pi pi-times",
                "dropdown-icon": "pi pi-chevron-down",
                model: [{ label: 'INCOMPLET', icon: 'pi pi-check', class: '-incomplete', command: () => $setup.changeStatus(false) }]
              }, null, 8 /* PROPS */, ["model"]))
            : (_openBlock(), _createBlock($setup["SplitButton"], {
                key: 3,
                class: "-incomplete",
                label: "INCOMPLET",
                icon: "pi pi-check",
                "dropdown-icon": "pi pi-chevron-down",
                model: [{ label: 'COMPLET', icon: 'pi pi-times', class: '-full', command: () => $setup.changeStatus(true) }]
              }, null, 8 /* PROPS */, ["model"]))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}