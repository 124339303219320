<script setup>
import { Form } from "@primevue/forms"
import { storeToRefs } from "pinia"
import { useToast } from "primevue"
import DatePicker from "primevue/datepicker"
import Dialog from "primevue/dialog"
import FloatLabel from "primevue/floatlabel"
import Select from "primevue/select"
import { ref } from "vue"

import AbsenceTimeline from "../_partials/AbsenceTimeline.vue"

import { getFullDate, getIsoString } from "../../../../../../ui/static_src/ui/utils/date.js"
import { getStaff, patchAbsence, postAbsence } from "../../../services/api.js"
import useStaffStore from "../../../stores/staff.js"

const staff = useStaffStore()
const toast = useToast()
const loading = ref(false)
const { focusedAbsence, isAbsenceModalOpen, focusedStaff, staffObject } = storeToRefs(staff)

const updateModel = (key, value) => {
  focusedAbsence.value[key] = value
}

const halfDayNames = {
  morning: "Matin",
  afternoon: "Après-midi",
}

const halfDayValues = {
  Matin: "morning",
  "Après-midi": "afternoon",
}

const resolver = () => {
  const errors = { start_date: [], end_date: [], start_at: [], end_at: [] }
  if (!focusedAbsence.value.end_at) errors.end_at.push({ type: "required" })
  if (!focusedAbsence.value.start_at) errors.start_at.push({ type: "required" })
  if (!focusedAbsence.value.start_date) errors.start_date.push({ type: "required" })
  if (!focusedAbsence.value.end_date) errors.end_date.push({ type: "required" })
  return { errors }
}

const onFormSubmit = async({ valid }) => {
  if (!valid) return
  loading.value = true
  const absenceMessage = `L'absence du ${getFullDate(focusedAbsence.value.start_date)} - ${halfDayNames[focusedAbsence.value.start_at]} au ${getFullDate(focusedAbsence.value.end_date)} - ${halfDayNames[focusedAbsence.value.end_at]}`
  const absence = { ...focusedAbsence.value, staff_id: focusedStaff.value.id, start_date: getIsoString(focusedAbsence.value.start_date), end_date: getIsoString(focusedAbsence.value.end_date) }
  if (!focusedAbsence.value.id) {
    await postAbsence(absence)
    toast.add({
      severity: "success",
      summary: "Contrat ajouté",
      detail: `${absenceMessage} a bien été ajoutée`,
      life: 4000,
    })
  } else {
    await patchAbsence(absence)
    toast.add({
      severity: "success",
      summary: "Compagnon modifié",
      detail: `${absenceMessage} a bien été modifiée`,
      life: 4000,
    })
  }
  if (staffObject.value?.id) {
    const { data } = await getStaff(staffObject.value.id)
    staffObject.value = data
  } else await staff.fetchStaff()
  loading.value = false
  staff.isAbsenceModalOpen = false
}
</script>

<template>
  <Dialog
    v-model:visible="isAbsenceModalOpen"
    :header="focusedAbsence?.id ? 'Modifier l\'absence en cours' : 'Ajouter une absence'"
    modal
  >
    <Form
      :resolver="resolver"
      @submit="onFormSubmit"
    >
      <div class="absence-form-block">
        <FloatLabel variant="on">
          <DatePicker
            id="start_date"
            name="start_date"
            :model-value="focusedAbsence.start_date"
            style="width: 12.5rem;"
            date-format="dd/mm/yy"
            @update:model-value="(value) => updateModel('start_date', value)"
          />
          <label for="start_date">Date de début</label>
        </FloatLabel>
        <FloatLabel variant="on">
          <Select
            id="start_at"
            name="start_at"
            :model-value="halfDayNames[focusedAbsence.start_at]"
            :options="['Matin', 'Après-midi']"
            style="width: 12.5rem;"
            @value-change="(value) => updateModel('start_at', halfDayValues[value])"
          />
          <label for="start_at">Demi-journée de début</label>
        </FloatLabel>
      </div>
      <div class="absence-form-block -margin-top">
        <FloatLabel variant="on">
          <DatePicker
            id="end_date"
            name="end_date"
            :model-value="focusedAbsence.end_date"
            style="width: 12.5rem;"
            date-format="dd/mm/yy"
            @update:model-value="(value) => updateModel('end_date', value)"
          />
          <label for="end_date">Date de fin</label>
        </FloatLabel>
        <FloatLabel variant="on">
          <Select
            id="end_at"
            name="end_at"
            :model-value="halfDayNames[focusedAbsence.end_at]"
            :options="['Matin', 'Après-midi']"
            style="width: 12.5rem;"
            @value-change="(value) => updateModel('end_at', halfDayValues[value])"
          />
          <label for="end_at">Demi-journée de fin</label>
        </FloatLabel>
      </div>
      <div class="absence-form-validate">
        <i
          v-if="loading"
          class="pi pi-spin pi-cog loading-icon"
        />
        <button
          v-else
          class="absence-form-button"
          type="submit"
        >
          {{ focusedAbsence?.id ? 'Modifier' : 'Ajouter' }}
        </button>
      </div>
      <div
        v-if="staffObject.absence_set.length > 0"
        class="absence-form-timeline"
      >
        <div class="absence-form-title">
          Historique
        </div>
        <AbsenceTimeline is-dialog />
      </div>
    </Form>
  </Dialog>
</template>

<style lang="scss" scoped>
@import '../../../../../../ui/static_src/ui/base/mixins';

.absence-form-block {
  display: flex;
  gap: 1rem;
  margin-top: .4rem;

  &.-margin-top {
    margin-top: 1rem;
  }

  &.-column {
    flex-direction: column;
  }
}

.absence-form-validate {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.absence-form-button {
  @include body;
  color: var(--color-text-quaternary);
  background-color: var(--color-button-bg-submit-full);
  padding: .4rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--color-button-bg-submit-full-hover);
  }
}

.absence-form-timeline {
  margin-top: 1.4rem;
}

.absence-form-title {
  @include body;
  margin-bottom: 1.4rem;
}
</style>
