import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "car-followup-tooltip" }
const _hoisted_2 = {
  key: 0,
  class: "car-followup-point-container"
}
const _hoisted_3 = { class: "car-followup-point-container" }
const _hoisted_4 = { class: "car-followup-point-container" }
const _hoisted_5 = { class: "car-followup-point-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$props.car.insurances.find((insurance) => insurance.name === 'SANS ASSURANCE'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          ($props.car.expert_report_received)
            ? (_openBlock(), _createBlock($setup["Check"], {
                key: 0,
                class: "followup -complete"
              }))
            : (_openBlock(), _createBlock($setup["X"], {
                key: 1,
                class: "followup -incomplete"
              })),
          _cache[0] || (_cache[0] = _createTextVNode(" Rapport d'expert reçu "))
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_3, [
      ($props.car.guarantee_given)
        ? (_openBlock(), _createBlock($setup["Check"], {
            key: 0,
            class: "followup -complete"
          }))
        : (_openBlock(), _createBlock($setup["X"], {
            key: 1,
            class: "followup -incomplete"
          })),
      _cache[1] || (_cache[1] = _createTextVNode(" Chèque de garantie donné "))
    ]),
    _createElementVNode("div", _hoisted_4, [
      ($props.car.repair_order)
        ? (_openBlock(), _createBlock($setup["Check"], {
            key: 0,
            class: "followup -complete"
          }))
        : (_openBlock(), _createBlock($setup["X"], {
            key: 1,
            class: "followup -incomplete"
          })),
      _cache[2] || (_cache[2] = _createTextVNode(" Ordre de réparation prêt "))
    ]),
    _createElementVNode("div", _hoisted_5, [
      ($props.car.valid_support)
        ? (_openBlock(), _createBlock($setup["Check"], {
            key: 0,
            class: "followup -complete"
          }))
        : (_openBlock(), _createBlock($setup["X"], {
            key: 1,
            class: "followup -incomplete"
          })),
      _cache[3] || (_cache[3] = _createTextVNode(" Prise en charge valide "))
    ])
  ]))
}