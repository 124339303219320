import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "staff-loading-data"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.staff.staffObject)
      ? (_openBlock(), _createBlock($setup["StaffDetailToolbar"], { key: 0 }))
      : _createCommentVNode("v-if", true),
    ($setup.staff.staffObject)
      ? (_openBlock(), _createBlock($setup["StaffDetail"], { key: 1 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("i", { class: "pi pi-spin pi-cog loading-icon" }, null, -1 /* HOISTED */)
        ])))
  ], 64 /* STABLE_FRAGMENT */))
}