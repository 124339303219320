<script setup>
import { CircleCheck, Trash2, Upload, X } from "lucide-vue-next"
import { computed } from "vue"

const emit = defineEmits(["open-document-preview", "delete-document", "file-selected"])

const props = defineProps({
  file: {
    type: Object,
    default: null,
  },
  errorRaised: {
    type: Boolean,
    default: false,
  },
  acceptedFormats: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
})

const size = computed(() => {
  if (!props.file) return 0
  const sizeKB = Math.round(props.file.size / 1024)
  if (sizeKB < 999) return `${sizeKB} KB`
  return `${Math.round(sizeKB / 102.4) / 10} MB`
})

const handleDeleteDocument = (event) => {
  emit("delete-document")
  event.preventDefault()
  event.stopPropagation()
}

const handleOpenDocumentPreview = (event) => {
  emit("open-document-preview")
  event.preventDefault()
  event.stopPropagation()
}
</script>

<template>
  <div
    v-if="file"
    class="document-file-box"
  >
    <div class="document-file">
      <div class="document-label">
        {{ label }}
      </div>
      <div class="document-file-detail">
        <a
          v-if="file.absolute_path"
          class="document-file-text"
          :href="file.absolute_path"
          target="_blank"
        >
          {{ file.name }}
        </a>
        <button
          v-else
          @click="handleOpenDocumentPreview"
        >
          <div class="document-file-text">
            {{ file.name }}
          </div>
        </button>
        <div class="document-file-text -secondary">
          {{ size }}
        </div>
      </div>
      <div
        v-if="file.progression !== 100"
        class="document-upload-progress-bar"
      >
        <div
          v-if="file.progression > 0"
          class="document-upload-progress"
          :style="{ width: `${file.progression}%` }"
        >
          {{ file.progression }}%
        </div>
      </div>
      <div
        v-else-if="!errorRaised"
        class="document-file-detail -small-gap"
      >
        <CircleCheck
          size="14"
          class="document-file-success"
          :stroke-width="2.5"
        />
        <div class="document-file-text -secondary -success">
          Chargé
        </div>
      </div>
      <div
        v-else
        class="document-file-detail -small-gap"
      >
        <X
          size="14"
          class="document-file-failure"
          :stroke-width="2.5"
        />
        <div class="document-file-text -secondary -failure">
          Impossible d'extraire les données
        </div>
      </div>
    </div>
    <button
      v-if="file.progression === 100"
      v-tooltip.left="{ content: 'Supprimer le document', delay: 1000 }"
      class="document-file-button"
      :disabled="file.progression !== 100"
      @click="handleDeleteDocument"
    >
      <Trash2
        size="18"
        class="document-file-trash"
      />
    </button>
  </div>
  <div
    v-else
    class="file-input"
  >
    <Upload
      size="24"
      class="file-input-icon"
    />
    <input
      class="document-file-input"
      type="file"
      name="file"
      :accept="acceptedFormats"
      @change="emit('file-selected', $event)"
    >
    <div class="document-file-label">
      {{ placeholder }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../../../ui/static_src/ui/base/mixins";

.document-file-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--p-form-field-border-color);
  padding: .4rem;
  height: 4rem;
  gap: 1rem;
  border-radius: var(--radius);
  margin-bottom: .6rem;
  margin-top: .6rem;
  overflow: visible;
}

.document-file {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.document-label {
  @include body;
  color: var(--p-floatlabel-active-color);
  position: absolute;
  transform: translateY(-80%) translateX(6%);
  background-color: var(--content-bg--color-lighter);
  width: fit-content;
  padding: 0 .1rem;
  text-align: center;
}

.document-file-detail {
  display: flex;
  gap: 1rem;
  align-items: center;

  &.-small-gap {
    gap: .4rem;
  }
}

.document-file-text {
  @include body;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 16rem;

  &.-secondary {
    color: var(--color-text-secondary);
  }

  &.-success {
    color: var(--color-text-success);
  }

  &.-failure {
    color: var(--color-text-warning);
  }
}

.document-file-trash {
  color: var(--color-text-secondary);
}

.document-file-success {
  color: var(--color-text-success);
  margin-left: 6px;
}

.document-file-failure {
  color: var(--color-text-warning);
}

.document-file-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover .document-file-trash {
    color: var(--color-bg-warning);
  }
}

.document-upload-progress-bar {
  width: 100%;
  height: 1rem;
  background: var(--content-bg--color-light);
  border-radius: var(--radius-max);
  margin-top: .4rem;
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.313);
}

.document-upload-progress {
  @include subbody;
  height: 100%;
  background: var(--content-bg--color-focus);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 1rem;
  transition: width 0.3s ease-in-out;
}

.file-input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-s);
  width: 100%;
  height: 4rem;
  border: 1px dashed var(--color-border-secondary);
  border-radius: var(--radius);
  flex-direction: column;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-bottom: .6rem;
  background: var(--content-bg--color-lighter);

  &:hover {
    background: var(--content-bg--color-focus);
  }
}

.file-input-icon {
  color: var(--color-text-secondary);
  transition: color 0.3s ease;
}

.document-file-input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.document-file-label {
  @include body;
  color: var(--color-text-secondary);
  transition: color 0.3s ease;
}

.document-upload-box:hover .document-upload-icon,
.document-upload-box:hover .document-file-label {
  color: var(--color-text-primary);
}
</style>
