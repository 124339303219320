<script setup>
import { Dialog } from "primevue"
import { ref, watch } from "vue"

import DocumentInput from "./_partials/DocumentInput.vue"

import { deleteRegistrationCar, extractRegistrationCarData } from "../services/api.js"

const emit = defineEmits(["data-extracted"])

const props = defineProps({
  car: {
    type: Object,
    default: null,
  },
})

const file = ref(null)
const fileAsBase64Image = ref(null)
const openPreviewModal = ref(false)
const errorRaised = ref(false)

const handleFileSelected = (event) => {
  const content = event.target.files[0]
  file.value = { content, name: content.name, size: content.size, progression: 0 }
  const formData = new FormData()
  formData.append("file", file.value.content)

  const reader = new FileReader()
  reader.onload = (event) => {
    fileAsBase64Image.value = event.target.result
  }
  reader.readAsDataURL(content)

  extractRegistrationCarData(formData).then(({ data }) => {
    file.value.progression = 100
    if (data.error === "Error extracting data") {
      errorRaised.value = true
      return
    }
    emit("data-extracted", { ...data, file: file.value.content, filename: file.value.name, size: file.value.size })
  })

  // AI Inferencing takes approximately 7s
  const interval = setInterval(() => {
    file.value.progression = Math.min(file.value.progression + 15, 99)
    if (file.value.progression >= 99) {
      clearInterval(interval)
    }
  }, 1000)
}

const deleteFile = (event) => {
  event.preventDefault()
  event.stopPropagation()
  if (file.value.progression !== 100) return
  if (file.value.id) deleteRegistrationCar(file.value.id)
  file.value = null
  errorRaised.value = false
}

watch(() => props.car, (newCar) => {
  if (newCar?.registration_car) {
    file.value = {
      name: newCar.registration_car.original_filename,
      size: newCar.registration_car.size,
      absolute_path: newCar.registration_car.absolute_path,
      progression: 100,
      id: newCar.registration_car.id,
    }
  }
}, { deep: true, immediate: true })
</script>

<template>
  <DocumentInput
    :file="file"
    :error-raised="errorRaised"
    accepted-formats=".pdf,.jpeg,application/pdf,image/jpeg"
    placeholder="Carte grise en format pdf ou jpeg"
    label="Carte grise"
    @file-selected="handleFileSelected"
    @delete-file="deleteFile"
    @open-document-preview="openPreviewModal = true"
  />
  <Dialog
    v-model:visible="openPreviewModal"
    class="document-preview"
  >
    <img
      v-if="file.content.type.includes('image')"
      :src="fileAsBase64Image"
      height="600"
      width="400"
    >
    <iframe
      v-else
      :src="fileAsBase64Image"
      height="600"
      width="400"
    />
  </Dialog>
</template>
