import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createBlock($setup["SpeedDial"], {
    direction: "left",
    model: $setup.items,
    class: "staff-speed-dial",
    onHide: _cache[0] || (_cache[0] = $event => ($setup.isButtonToggled = false)),
    onShow: _cache[1] || (_cache[1] = $event => ($setup.isButtonToggled = true))
  }, {
    button: _withCtx(({ toggleCallback }) => [
      _createElementVNode("button", {
        class: "staff-speed-dial-button",
        onClick: toggleCallback
      }, [
        _createVNode(_Transition, {
          name: "fade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (!$setup.isButtonToggled)
              ? (_openBlock(), _createBlock($setup["EllipsisVertical"], { key: 0 }))
              : (_openBlock(), _createBlock($setup["X"], { key: 1 }))
          ]),
          _: 1 /* STABLE */
        })
      ], 8 /* PROPS */, _hoisted_1)
    ]),
    item: _withCtx(({ item }) => [
      (item.label === 'new-contract')
        ? _withDirectives((_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "staff-speed-dial-item-button",
            onClick: $setup.openContractModal
          }, [
            _createVNode($setup["FilePlus2"])
          ])), [
            [
              _directive_tooltip,
              { value: 'Nouveau contrat', showDelay: 500 },
              void 0,
              { bottom: true }
            ]
          ])
        : (item.label === 'update-current-contract')
          ? _withDirectives((_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "staff-speed-dial-item-button",
              onClick: $setup.openContractModal
            }, [
              _createVNode($setup["FilePenLine"])
            ])), [
              [
                _directive_tooltip,
                { value: 'Modifier le contrat en cours', showDelay: 500 },
                void 0,
                { bottom: true }
              ]
            ])
          : (item.label === 'new-absence')
            ? _withDirectives((_openBlock(), _createElementBlock("button", {
                key: 2,
                class: "staff-speed-dial-item-button",
                onClick: $setup.openAbsenceModal
              }, [
                _createVNode($setup["CalendarPlus"])
              ])), [
                [
                  _directive_tooltip,
                  { value: 'Nouvelle absence', showDelay: 500 },
                  void 0,
                  { bottom: true }
                ]
              ])
            : (item.label === 'update-current-absence')
              ? _withDirectives((_openBlock(), _createElementBlock("button", {
                  key: 3,
                  class: "staff-speed-dial-item-button",
                  onClick: $setup.openAbsenceModal
                }, [
                  _createVNode($setup["CalendarClock"])
                ])), [
                  [
                    _directive_tooltip,
                    { value: 'Modifier l\'absence en cours', showDelay: 500 },
                    void 0,
                    { bottom: true }
                  ]
                ])
              : _withDirectives((_openBlock(), _createElementBlock("button", {
                  key: 4,
                  class: "staff-speed-dial-item-button",
                  onClick: $setup.openStaffModal
                }, [
                  _createVNode($setup["Pencil"])
                ])), [
                  [
                    _directive_tooltip,
                    { value: 'Modifier les informations du compagnon', showDelay: 500 },
                    void 0,
                    { bottom: true }
                  ]
                ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["model"]))
}