<script setup>
import { UserRound } from "lucide-vue-next"
import FloatLabel from "primevue/floatlabel"
import InputText from "primevue/inputtext"
import { computed } from "vue"

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
})

const isCreation = computed(() => !props.modelValue.id)
const emit = defineEmits(["update:modelValue"])

const updateModel = (key, value, event = null) => {
  if (event) {
    event.preventDefault()
    event.stopPropagation()
  }

  emit("update:modelValue", {
    ...props.modelValue,
    [key]: value,
  })
}
</script>

<template>
  <div class="car-incharge-form-block">
    <div class="car-form-subtitle">
      <UserRound size="24" />
      Propriétaire
    </div>
    <div class="car-incharge-form-block -row -no-padding">
      <FloatLabel
        v-if="!isCreation && !modelValue.owner_first_name"
        variant="on"
      >
        <InputText
          id="owner_first_name"
          name="owner_first_name"
          :model-value="modelValue.owner_first_name"
          style="min-width: 12.5rem;"
          @update:model-value="(value) => updateModel('owner_first_name', value)"
        />
        <label for="owner_first_name">Prénom</label>
      </FloatLabel>
      <FloatLabel
        variant="on"
        :style="isCreation ? 'width: 100%;' : ''"
      >
        <InputText
          id="owner_last_name"
          name="owner_last_name"
          :model-value="modelValue.owner_last_name"
          :style="isCreation ? 'width: 100%;' : 'min-width: 12.5rem;'"
          @update:model-value="(value) => updateModel('owner_last_name', value)"
        />
        <label for="owner_last_name">Nom</label>
      </FloatLabel>
    </div>
    <div class="car-incharge-form-block -row -no-padding">
      <FloatLabel variant="on">
        <InputText
          id="owner_phone_number"
          name="owner_phone_number"
          :model-value="modelValue.owner_phone_number"
          style="min-width: 12.5rem;"
          @update:model-value="(value) => updateModel('owner_phone_number', value)"
        />
        <label for="owner_phone_number">Numéro de téléphone 1</label>
      </FloatLabel>
      <FloatLabel variant="on">
        <InputText
          id="owner_phone_number_2"
          name="owner_phone_number_2"
          :model-value="modelValue.owner_phone_number_2"
          style="min-width: 12.5rem;"
          @update:model-value="(value) => updateModel('owner_phone_number_2', value)"
        />
        <label for="owner_phone_number_2">Numéro de téléphone 2</label>
      </FloatLabel>
    </div>
    <FloatLabel
      v-if="!isCreation || modelValue.owner_email"
      variant="on"
    >
      <InputText
        id="owner_email"
        name="owner_email"
        :model-value="modelValue.owner_email"
        style="width: 100%;"
        @update:model-value="(value) => updateModel('owner_email', value)"
      />
      <label for="owner_phone_number_2">Email</label>
    </FloatLabel>
    <FloatLabel
      v-if="!isCreation || modelValue.owner_address"
      variant="on"
    >
      <InputText
        id="owner_address"
        name="owner_address"
        :model-value="modelValue.owner_address"
        style="width: 100%;"
        @update:model-value="(value) => updateModel('owner_address', value)"
      />
      <label for="owner_address">Adresse</label>
    </FloatLabel>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../../../../ui/static_src/ui/base/mixins';

.car-incharge-form-block {
  display: flex;
  flex-direction: column;
  padding: .4rem 0rem;
  gap: 1rem;
  width: 100%;

  &.-row {
    flex-direction: row;
    justify-content: space-between;
  }

  &.-no-padding {
    padding: 0;
  }
}

.car-form-subtitle {
  @include subtitle;
  display: flex;
  align-items: center;
  gap: .4rem;
  margin-top: .4rem;
}

.car-form-subtitle-icon {
  width: 1.5rem;
  height: 1.5rem;
}
</style>
