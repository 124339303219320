import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "staff-timeline-contract"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "staff-timeline-date -flex-end" }
const _hoisted_4 = {
  key: 0,
  class: "staff-timeline-marker -start"
}
const _hoisted_5 = {
  key: 1,
  class: "staff-timeline-marker -end"
}
const _hoisted_6 = {
  key: 2,
  class: "staff-timeline-marker-date"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return ($setup.staffObject.contract_set.length > 0)
    ? (_openBlock(), _createBlock($setup["Timeline"], {
        key: 0,
        value: $setup.contractTimeline,
        class: "staff-timeline-content"
      }, {
        content: _withCtx((slotProps) => [
          (slotProps.item.text)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createTextVNode(_toDisplayString(slotProps.item.text) + " ", 1 /* TEXT */),
                _withDirectives((_openBlock(), _createElementBlock("button", {
                  class: "staff-timeline-edit",
                  onClick: $event => ($setup.staff.openContractModal(
            $setup.staffObject,
            $setup.staffObject.contract_set.find(c => c.id === slotProps.item.contractId)
          ))
                }, [
                  _createVNode($setup["Pencil"], { size: "14" })
                ], 8 /* PROPS */, _hoisted_2)), [
                  [
                    _directive_tooltip,
                    { value: 'Modifier le contrat', showDelay: 500 },
                    void 0,
                    { top: true }
                  ]
                ])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        opposite: _withCtx((slotProps) => [
          _createElementVNode("div", _hoisted_3, _toDisplayString(slotProps.item.date ? $setup.getFullDate(slotProps.item.date) : '...'), 1 /* TEXT */)
        ]),
        marker: _withCtx((slotProps) => [
          (slotProps.item.dateType === 'start' && $setup.isBeforeToday(slotProps.item.date))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode($setup["Check"], {
                  size: "14",
                  class: "staff-timeline-icon"
                })
              ]))
            : (slotProps.item.dateType === 'end' && slotProps.item.date && $setup.isBeforeToday(slotProps.item.date))
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode($setup["Check"], {
                    size: "14",
                    class: "staff-timeline-icon"
                  })
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_6))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["value"]))
    : _createCommentVNode("v-if", true)
}