import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "staff-toolbar" }
const _hoisted_2 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      type: "button",
      class: "body__toolbar-button-menu -persistent",
      href: `/${$setup.route.params.companySlug}/compagnons/`
    }, [
      _createVNode($setup["ArrowRight"], { class: "car__go-back-icon" })
    ], 8 /* PROPS */, _hoisted_2),
    _withDirectives((_openBlock(), _createElementBlock("button", {
      class: "-highlighted staff-button",
      onClick: _cache[0] || (_cache[0] = $event => ($setup.staff.openStaffModal($setup.staffObject)))
    }, [
      _createVNode($setup["UserPen"])
    ])), [
      [
        _directive_tooltip,
        'Modifier les informations du compagnon',
        void 0,
        { left: true }
      ]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("button", {
      class: "-highlighted staff-button",
      onClick: _cache[1] || (_cache[1] = $event => ($setup.staff.openAbsenceModal($setup.staffObject)))
    }, [
      _createVNode($setup["CalendarPlus"])
    ])), [
      [
        _directive_tooltip,
        'Ajouter une absence',
        void 0,
        { left: true }
      ]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("button", {
      class: "-highlighted staff-button",
      onClick: _cache[2] || (_cache[2] = $event => ($setup.staff.openContractModal($setup.staffObject)))
    }, [
      _createVNode($setup["FilePlus2"])
    ])), [
      [
        _directive_tooltip,
        'Ajouter un contrat',
        void 0,
        { left: true }
      ]
    ])
  ]))
}