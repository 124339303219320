<script setup>
import { UserPlus } from "lucide-vue-next"

import useStaffStore from "../../stores/staff.js"

const staff = useStaffStore()
</script>

<template>
  <div class="staff-toolbar">
    <button
      v-tooltip.left="'Ajouter un copmagnon'"
      class="-highlighted staff-button"
      @click="staff.openStaffModal()"
    >
      <UserPlus />
    </button>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src/ui/base/mixins';

.staff-toolbar {
  display: flex;
  padding: 1rem;
  border-bottom: 0.4px solid var(--color-border-primary);
  justify-content: flex-start;
}

.staff-button {
  @include body;
  color: var(--color-text-quaternary);
  padding: .5rem;
}
</style>
