<script setup>
import { asyncComputed } from "@vueuse/core/index.cjs"
import SplitButton from "primevue/splitbutton"
import { useToast } from "primevue/usetoast"
import { h, ref } from "vue"
import { renderToString } from "vue/server-renderer"

import RepairingStatesDialog from "../dialogs/RepairingStates.vue"
import WaitingDateDialog from "../dialogs/WaitingDate.vue"
import CarState from "../tooltips/CarState.vue"

import { carObject, getStateList, stateName } from "../../../../../companies/static_src/companies/utils/cars.js"
import { getFullDate } from "../../../../../ui/static_src/ui/utils/date.js"
import { svgLink } from "../../../../../ui/static_src/ui/utils/svg.js"

const props = defineProps({
  object: {
    type: Object,
    required: true,
  },
  updateCarState: {
    type: Function,
    required: true,
  },
  updateCarInCharge: {
    type: Function,
    required: true,
  },
  isHomepage: {
    type: Boolean,
    default: false,
  },
})

const toast = useToast()
const repairingStatesModalOpen = ref(false)
const waitingDateModalOpen = ref(false)

const openStates = (event) => {
  event.preventDefault()
  event.stopPropagation()
  event.currentTarget.children[0].children[0].nextElementSibling.click()
}

const onSelectState = async(state) => {
  if (state === "repairing") {
    repairingStatesModalOpen.value = true
    return
  }
  if (state === "waiting") {
    waitingDateModalOpen.value = true
    return
  }
  await props.updateCarState(props.object, state, null)
  toast.add({
    severity: "success",
    summary: `${carObject(props.object).owner_last_name} | ${carObject(props.object).model}`,
    detail: `Le véhicule est maintenant dans l'état : ${stateName(state)}`,
    life: 4000,
  })
}

const onSelectRepairingState = async(states) => {
  await props.updateCarState(
    props.object,
    "repairing",
    [states[0] || "À faire", states[1] || "À faire", states[2] || "À faire"],
  )
  toast.add({
    severity: "success",
    summary: `${carObject(props.object).owner_last_name} | ${carObject(props.object).model}`,
    detail: `Le véhicule est maintenant dans l'état : ${stateName("repairing")}`,
    life: 4000,
  })

  repairingStatesModalOpen.value = false
}

const onSelectWaitingDate = async(date) => {
  if (!date) return
  const car = carObject(props.object)
  await props.updateCarInCharge({ ...car, waiting_date: date })
  await props.updateCarState(props.object, "waiting", null)
  toast.add({
    severity: "success",
    summary: `${car.owner_last_name} | ${car.model}`,
    detail: `Le véhicule est maintenant en attente depuis le ${getFullDate(date)}`,
    life: 4000,
  })

  waitingDateModalOpen.value = false
}

const stateTooltipOptions = asyncComputed(async() => {
  const htmlContent = await renderToString(h(CarState, { object: props.object }))
  return { escape: false, value: htmlContent }
})
</script>

<template>
  <button
    :id="`car-${carObject(object).id}`"
    v-tooltip.left="stateTooltipOptions"
    :class="['car-state-container', carObject(object).is_deleted ? '-deleted' : '']"
    @click="openStates"
  >
    <div
      v-if="isHomepage && object.type === 'car'"
      :class="`car__card-state -${carObject(object).state}`"
    />
    <svg
      v-else-if="isHomepage && object.type === 'work'"
      :class="`car__delay-work-icon -${carObject(object).state}`"
    >
      <use :href="svgLink('cars', 'delay')" />
    </svg>
    <SplitButton
      v-else
      class="car-state"
      :model="getStateList()"
      :pt="{ pcMenu: { root: { class: 'car-state-menu' } } }"
    >
      <template #item="slotProps">
        <button
          v-if="slotProps.item.value !== carObject(object).state || carObject(object).state === 'repairing'"
          class="state-selecter"
          @click="onSelectState(slotProps.item.value)"
        >
          <div :class="`car__card-state -${slotProps.item.value}`" />
          {{ slotProps.item.label }}
        </button>
        <button
          v-else
          class="state-selecter"
          disabled
        >
          <div :class="`car__card-state -${slotProps.item.value}`" />
          {{ slotProps.item.label }}
        </button>
      </template>
      <div
        v-if="object.type === 'car'"
        :class="`car__card-state -${carObject(object).state}`"
      />
      <svg
        v-else
        :class="`car__delay-work-icon -${carObject(object).state}`"
      >
        <use :href="svgLink('cars', 'delay')" />
      </svg>
    </SplitButton>
    <RepairingStatesDialog
      v-model="repairingStatesModalOpen"
      :object="object"
      :car="carObject(object)"
      :handle-validate="onSelectRepairingState"
    />
    <WaitingDateDialog
      v-model="waitingDateModalOpen"
      :object="object"
      :car="carObject(object)"
      :handle-validate="onSelectWaitingDate"
    />
  </button>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src/ui/base/mixins';

.car-state {
  display: flex;
  width: .8rem;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  min-width: 0px;
  cursor: pointer;
}

.state-selecter {
  @include body;
  width: 7rem;
  padding: .1rem .4rem;
  border-radius: var(--radius);

  &:disabled:hover {
    background-color: transparent
  }
}

.car-state-container {
  padding: 0;
  margin: 0;
  padding-inline: 0;
  padding-block: 0;

  &:hover {
    background-color: transparent;
  }

  &.-deleted {
    opacity: .4;
  }
}
</style>
