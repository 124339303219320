import { createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["Toast"]),
    _createVNode($setup["AbsenceDialog"]),
    _createVNode($setup["RouterView"]),
    _createVNode($setup["ContractDialog"]),
    _createVNode($setup["StaffDialog"])
  ], 64 /* STABLE_FRAGMENT */))
}