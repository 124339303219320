import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "calendar-week-day-car-container" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "calendar-week-day-car-slot" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "tow-truck-icon" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "calendar-week-day-car-slot" }
const _hoisted_9 = {
  key: 0,
  class: "calendar-car-missing-points"
}
const _hoisted_10 = {
  key: 0,
  class: "missing-points-separator"
}
const _hoisted_11 = {
  key: 0,
  class: "missing-points-separator"
}
const _hoisted_12 = {
  key: 0,
  class: "missing-points-separator"
}
const _hoisted_13 = {
  key: 1,
  class: "calendar-week-day-car-note-container"
}
const _hoisted_14 = { class: "calendar-week-day-car-note" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock("div", {
    ref: "containerRef",
    class: _normalizeClass([
      'calendar-week-day-car',
      $setup.isOnlyMechanic($setup.car) ? '-mechanic' : '',
      $setup.car.due_date ? '-highlighted' : '',
      $setup.car.foreground_note ? '-with-note' : '',
      $setup.car.is_deleted ? '-deleted' : '',
      $setup.props.object.type === 'work' ? '-work' : '-car',
      $setup.isExpanded ? '-expanded' : '',
      $setup.props.draggable ? '-draggable' : '',
      $setup.isEndValidSUpportDateOver ? '-end-valid-support-date-over' : '',
      $setup.calendarStore.isCarHidden($setup.car) && $props.object.type === 'car' ? '-hidden' : '',
      $props.object.type === 'work' && $setup.calendarStore.isWorkHidden($props.object.work) ? '-hidden' : '',
      $props.object.type === 'car' && $setup.calendarStore.filteredWeeks.includes($setup.getIsoString($setup.getCurrentMonday($setup.getStateDate($setup.car)))) && !$setup.calendarStore.isCarHidden($setup.car) ? '-folder' : '',
      $props.object.type === 'work' && $setup.calendarStore.filteredWeeks.includes($setup.getIsoString($setup.getCurrentMonday($props.object.work.date))) && !$setup.calendarStore.isWorkHidden($props.object.work) ? '-folder' : '',
    ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode($setup["CarState"], {
        object: $props.object,
        "update-car-state": $setup.calendarStore.updateCarState,
        "update-car-in-charge": $setup.calendarStore.updateCarInCharge,
        "is-homepage": $setup.calendarStore.isHomepage
      }, null, 8 /* PROPS */, ["object", "update-car-state", "update-car-in-charge", "is-homepage"]),
      _createElementVNode("div", {
        ref: "carNameRef",
        class: "calendar-week-day-car-name",
        onClick: _cache[0] || (_cache[0] = $event => ($setup.toggleExpandedCar()))
      }, [
        _withDirectives((_openBlock(), _createElementBlock("span", {
          id: `car-${$setup.car.id}`,
          class: _normalizeClass(['car-name', { '-result': $setup.isSearchResult }])
        }, [
          _createTextVNode(_toDisplayString($setup.car.owner_last_name) + " | " + _toDisplayString($setup.car.model), 1 /* TEXT */)
        ], 10 /* CLASS, PROPS */, _hoisted_2)), [
          [
            _directive_tooltip,
            $setup.carNameTooltipOptions,
            void 0,
            { top: true }
          ]
        ])
      ], 512 /* NEED_PATCH */),
      _createElementVNode("div", _hoisted_3, [
        ($setup.car.is_seriously_damaged)
          ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_4, [
              _createVNode($setup["Flame"], {
                size: "18",
                class: "seriously-damaged-icon"
              })
            ])), [
              [
                _directive_tooltip,
                'VGE',
                void 0,
                { top: true }
              ]
            ])
          : ($setup.car.from_tow_truck)
            ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_5, [
                (_openBlock(), _createElementBlock("svg", _hoisted_6, [
                  _createElementVNode("use", {
                    href: $setup.svgLink('cars', 'tow-truck')
                  }, null, 8 /* PROPS */, _hoisted_7)
                ]))
              ])), [
                [
                  _directive_tooltip,
                  `Arrivé par dépannage le ${$setup.getFullDate($setup.car.waiting_date ? new Date($setup.car.waiting_date) : new Date($setup.getStateDate($setup.car)))}`,
                  void 0,
                  { top: true }
                ]
              ])
            : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        ($setup.car.need_replacement)
          ? (_openBlock(), _createBlock($setup["CalendarCarReplacement"], {
              key: 0,
              object: $setup.props.object
            }, null, 8 /* PROPS */, ["object"]))
          : _createCommentVNode("v-if", true)
      ]),
      _createVNode($setup["CalendarCarFollowUp"], { car: $setup.car }, null, 8 /* PROPS */, ["car"])
    ]),
    (($props.object.type === 'car' && $setup.calendarStore.filteredWeeks.includes($setup.getIsoString($setup.getCurrentMonday($setup.getStateDate($setup.car)))) && !$setup.calendarStore.isCarHidden($setup.car)) ||
        ($props.object.type === 'work' && $setup.calendarStore.filteredWeeks.includes($setup.getIsoString($setup.getCurrentMonday($props.object.work.date))) && !$setup.calendarStore.isWorkHidden($props.object.work)))
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (!$setup.car.repair_order)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _cache[1] || (_cache[1] = _createTextVNode(" Ordre de réparation ")),
                (!$setup.car.valid_support || !$setup.car.guarantee_given || !$setup.car.expert_report_received && !$setup.car.insurances.find((insurance) => insurance.name === 'SANS ASSURANCE'))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10))
                  : _createCommentVNode("v-if", true)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          (!$setup.car.valid_support)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _cache[2] || (_cache[2] = _createTextVNode(" Prise en charge ")),
                (!$setup.car.guarantee_given || !$setup.car.expert_report_received && !$setup.car.insurances.find((insurance) => insurance.name === 'SANS ASSURANCE'))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11))
                  : _createCommentVNode("v-if", true)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          (!$setup.car.guarantee_given)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _cache[3] || (_cache[3] = _createTextVNode(" Chèque de garantie ")),
                (!$setup.car.expert_report_received && !$setup.car.insurances.find((insurance) => insurance.name === 'SANS ASSURANCE'))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12))
                  : _createCommentVNode("v-if", true)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          (!$setup.car.expert_report_received && !$setup.car.insurances.find((insurance) => insurance.name === 'SANS ASSURANCE'))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createTextVNode(" Rapport d'expert ")
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.car.foreground_note)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createVNode($setup["StickyNote"], { size: "18" }),
          _createElementVNode("span", _hoisted_14, _toDisplayString($setup.car.foreground_note), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        ($setup.isExpanded)
          ? (_openBlock(), _createBlock($setup["CalendarCarOptions"], {
              key: 0,
              object: $setup.props.object
            }, null, 8 /* PROPS */, ["object"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ], 2 /* CLASS */))
}