// --- Server API ---

export const getCarsOfWeek = async(date, companyPk, body) => {
  return await api("GET", `/api/cars-in-charge/?monday=${date}&company_pk=${companyPk}`, null, body)
}

export const getCarSearch = async(query, companyPk, body) => {
  return await api("GET", `/api/cars-in-charge/?query=${query}&company_pk=${companyPk}&calendar=true`, null, body)
}

export const createCarInCharge = async(body) => {
  return await api("POST", `/api/cars-in-charge/?company_pk=${window.CALENDAR_ENV.company_pk}`, null, body)
}

export const updateCarInCharge = async(body) => {
  return await api("PATCH", `/api/cars-in-charge/${body.id}/?company_pk=${window.CALENDAR_ENV.company_pk}`, null, body)
}

export const updateDelayedWork = async(body) => {
  return await api("PATCH", `/api/delayed-works/${body.id}/?company_pk=${window.CALENDAR_ENV.company_pk}`, null, body)
}

export const createDelayedWork = async(body) => {
  return await api("POST", `/api/delayed-works/?company_pk=${window.CALENDAR_ENV.company_pk}`, null, body)
}

export const createReplacement = async(body) => {
  return await api("POST", `/api/replacements/?company_pk=${window.CALENDAR_ENV.company_pk}`, null, body)
}

export const updateReplacement = async(body) => {
  return await api("PATCH", `/api/replacements/${body.id}/?company_pk=${window.CALENDAR_ENV.company_pk}`, null, body)
}

export const deleteReplacement = async(body) => {
  return await api("DELETE", `/api/replacements/${body.id}/?company_pk=${window.CALENDAR_ENV.company_pk}`)
}

export const deleteDelayedWork = async(body) => {
  return await api("DELETE", `/api/delayed-works/${body.id}/?company_pk=${window.CALENDAR_ENV.company_pk}`)
}

export const getDelayedWorksOfWeek = async(date, companyPk, body) => {
  return await api("GET", `/api/delayed-works/?monday=${date}&company_pk=${companyPk}`, null, body)
}

export const getReplacementCars = async(companyPk, body) => {
  return await api("GET", `/api/cars-replacements/?company_pk=${companyPk}`, null, body)
}

export const getWeekData = async(date, companyPk, body) => {
  return await api("GET", `/api/companies/${companyPk}/week-data/?monday=${date}`, null, body)
}

export const markFullWeek = async(date, companyPk, body) => {
  return await api("POST", `/api/companies/${companyPk}/mark-week-full/?monday=${date}`, null, body)
}

export const createNote = async(body) => {
  return await api("POST", "/api/notes/", null, body)
}

export const updateNote = async(body) => {
  return await api("PATCH", `/api/notes/${body.id}/`, null, body)
}

export const deleteNote = async(body) => {
  return await api("DELETE", `/api/notes/${body.id}/`)
}

export const updateCarState = async(body) => {
  return await api("POST", `/api/cars-in-charge/${body.id}/change-state/?company_pk=${window.CALENDAR_ENV.company_pk}`, null, body)
}

export const getInsurances = async(name, excludeList) => {
  let url = `/api/insurances/?query=${name}`
  if (excludeList) {
    excludeList.forEach(pk => {
      url += `&exclude=${pk}`
    })
  }
  return await api("GET", url)
}

export const createInsurance = async(name) => {
  return await api("POST", "/api/insurances/", null, { name })
}

export const getReplacementsOfWeek = async(monday, companyPk) => {
  return await api("GET", `/api/replacements/?monday=${monday}&company_pk=${companyPk}`)
}

export const getMaintenanceTimeOfWeek = async(monday, companyPk) => {
  return await api("GET", `/api/maintenance-times/?monday=${monday}&company_pk=${companyPk}`)
}

export const getCarsInChargeNotUpdatedNotifications = async() => {
  return await api("GET", "/api/cars-not-updated-notifications/")
}

export const getReplacementCarNoReturnedNotifications = async() => {
  return await api("GET", "/api/replacement-car-not-returned-notifications/")
}

export const markNotificationsAsRead = async(notificationCarsId, notificationReplacementId) => {
  if (notificationCarsId) await api("POST", `/api/cars-not-updated-notifications/${notificationCarsId}/mark-viewed/`)
  if (notificationReplacementId) await api("POST", `/api/replacement-car-not-returned-notifications/${notificationReplacementId}/mark-viewed/`)
}

export const createRegistrationCar = async(form) => {
  return await api("POST", "/api/registration-car/", null, form)
}

export const getStaffList = async() => {
  return await api("GET", `/api/staff/?company_pk=${window.STAFF_ENV.company_pk}`)
}

export const postStaff = async(body) => {
  return await api("POST", `/api/staff/?company_pk=${window.STAFF_ENV.company_pk}`, null, body)
}

export const patchStaff = async(body) => {
  return await api("PATCH", `/api/staff/${body.id}/?company_pk=${window.STAFF_ENV.company_pk}`, null, body)
}

export const postContract = async(body) => {
  return await api("POST", `/api/contracts/?company_pk=${window.STAFF_ENV.company_pk}`, null, body)
}

export const patchContract = async(body) => {
  return await api("PATCH", `/api/contracts/${body.id}/?company_pk=${window.STAFF_ENV.company_pk}`, null, body)
}

export const postAbsence = async(body) => {
  return await api("POST", `/api/absences/?company_pk=${window.STAFF_ENV.company_pk}`, null, body)
}

export const patchAbsence = async(body) => {
  return await api("PATCH", `/api/absences/${body.id}/?company_pk=${window.STAFF_ENV.company_pk}`, null, body)
}

export const getStaff = async(id) => {
  return await api("GET", `/api/staff/${id}/?company_pk=${window.STAFF_ENV.company_pk}`)
}

export const deleteAbsence = async(id) => {
  return await api("DELETE", `/api/absences/${id}/?company_pk=${window.STAFF_ENV.company_pk}`)
}

// --- Internals ---

const api = async(method, path, options, body = null) => {
  if (body instanceof FormData) {
    return await apiXHR(method, path, options, body)
  }
  return await apiFetch(method, path, options, body)
}

const apiFetch = async(method, path, options, body = null) => {
  body = body || {}
  let data
  // eslint-disable-next-line compat/compat
  const response = await fetch(path, {
    method: method || "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": window.CSRF_TOKEN,
    },
    body: method === "GET" ? undefined : JSON.stringify(body),
  })
  if (response.ok && !path.includes("format=")) {
    try {
      data = await response.json()
    } catch (e) {
      // No-op
    }
  }
  return { response, data }
}

const apiXHR = async(method, path, options, body = null) => {
  options = options || {}
  let data
  // eslint-disable-next-line compat/compat
  const response = await new Promise((resolve) => {
    const xhr = new XMLHttpRequest()
    xhr.upload.onprogress = options?.onUploadProgress || (() => {})

    xhr.onload = () => { resolve(xhr) }

    xhr.open(method, path, true)
    xhr.setRequestHeader("X-CSRFToken", window.CSRF_TOKEN)
    if (options.token) {
      xhr.setRequestHeader("Authorization", `Token ${options.token}`)
    }
    xhr.send(body)
  })
  try {
    data = JSON.parse(response.responseText)
  } catch (e) {
    // No-op
  }
  return {
    response: { ...response, ok: response.status >= 200 && response.status < 300 },
    data,
  }
}
