<script setup>
import { Form } from "@primevue/forms"

import RegistrationCar from "../../../../../documents/static_src/documents/components/RegistrationCar.vue"
import CarDetails from "./car_form/CarDetails.vue"
import CarFolder from "./car_form/CarFolder.vue"
import CarOwner from "./car_form/CarOwner.vue"
import CarReplacement from "./car_form/CarReplacement.vue"
import CarWork from "./car_form/CarWork.vue"

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  handleValidate: {
    type: Function,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(["update:modelValue", "registration-car-data-extracted"])

const handleRegistrationCarExtractedData = (data) => {
  emit("registration-car-data-extracted", data)
}

const resolver = () => {
  const errors = { model: [], owner_last_name: [], breakage_date: [], expertise_date: [], waiting_date: [], start_work: [], insurances: [] }

  if (!props.modelValue.model) {
    errors.model.push({ type: "required", message: "Le modèle est requis" })
  }

  if (!props.modelValue.owner_last_name) {
    errors.owner_last_name.push({ type: "required", message: "Le nom du propriétaire est requis" })
  }

  if (!props.modelValue.insurances.length) {
    errors.insurances.push({ type: "required", message: "Ce champ est obligatoire. Si le véhicule n'est pas assuré, vous pouvez sélectionner l'option SANS ASSURANCE." })
  }

  switch (props.modelValue.state) {
    case "breakage":
      if (!props.modelValue.breakage_date) {
        errors.breakage_date.push({ type: "required", message: "La date de départ à la casse est requise" })
      }
      break
    case "expertise":
      if (!props.modelValue.expertise_date) {
        errors.expertise_date.push({ type: "required", message: "La date de l'expertise est requise" })
      }
      break
    case "waiting":
      if (!props.modelValue.waiting_date) {
        errors.waiting_date.push({ type: "required", message: "La date d'attente est requise" })
      }
      break
    default:
      if (!props.modelValue.start_work) {
        errors.start_work.push({ type: "required", message: "La date de début des réparations est requise" })
      }
      break
  }

  return { errors }
}

const onFormSubmit = ({ valid }) => {
  if (valid) props.handleValidate()
}
</script>

<template>
  <Form
    v-slot="$form"
    class="car-incharge-form"
    :resolver="resolver"
    @submit="onFormSubmit"
  >
    <RegistrationCar
      :car="modelValue"
      @data-extracted="handleRegistrationCarExtractedData"
    />
    <CarDetails
      :model-value="modelValue"
      @update:model-value="(updatedCar) => emit('update:modelValue', updatedCar)"
    />
    <CarOwner
      :model-value="modelValue"
      @update:model-value="(updatedCar) => emit('update:modelValue', updatedCar)"
    />
    <CarFolder
      :model-value="modelValue"
      @update:model-value="(updatedCar) => emit('update:modelValue', updatedCar)"
    />
    <slot :form="$form" />
    <CarReplacement
      :model-value="modelValue"
      @update:model-value="(updatedCar) => emit('update:modelValue', updatedCar)"
    />
    <CarWork
      :model-value="modelValue"
      @update:model-value="(updatedCar) => emit('update:modelValue', updatedCar)"
    />
    <div class="car-incharge-form-validate">
      <i
        v-if="loading"
        class="pi pi-spin pi-cog loading-icon"
      />
      <button
        v-else
        class="car-incharge-form-button"
        type="submit"
      >
        Valider
      </button>
    </div>
  </Form>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src/ui/base/mixins';

.car-incharge-form-validate {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.4rem;
}

.car-incharge-form-button {
  @include body;
  color: var(--color-text-quaternary);
  background-color: var(--color-button-bg-submit-full);
  padding: .4rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--color-button-bg-submit-full-hover);
  }

  &.-long {
    width: 14rem;
    align-self: center;
  }
}

.loading-icon {
  color: var(--color-text-primary);
  font-size: 1.2rem;
  margin-right: 3rem;
}
</style>
