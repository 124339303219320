<script setup>
import { orderBy } from "lodash-es"
import { storeToRefs } from "pinia"
import { DataTable } from "primevue"
import Column from "primevue/column"
import Tag from "primevue/tag"
import { computed } from "vue"

import StaffSpeedDial from "./StaffSpeedDial.vue"

import useStaffStore from "../../stores/staff.js"
import { getContractWeekDays, getCurrentAbsence, getPresenceSeverity, isContractStaffActive } from "../../utils/staff.js"

const staff = useStaffStore()
const { staffList } = storeToRefs(staff)

const staffListValue = computed(() => {
  return orderBy(staffList.value.map((staff) => {
    const currentContract = staff.contract_set && orderBy(staff.contract_set, "start_date", "desc")[0]
    const currentAbsence = getCurrentAbsence(staff)
    const isContractActive = currentContract && isContractStaffActive(currentContract)
    return {
      name: `${staff.first_name} ${staff.last_name}`,
      domain: {
        "sheet-metal-worker": "Tôlier",
        painter: "Peintre",
        mechanic: "Mécanicien",
      }[staff.domain],
      rank: {
        apprentice: "Apprenti",
        senior: "Sénior",
      }[staff.rank],
      contractType: currentContract
        ? {
            cdi: "CDI",
            cdd: "CDD",
            freelance: "Indépendant",
          }[currentContract.type]
        : "-",
      contractWeeklyHours: currentContract ? currentContract.weekly_hours : "-",
      contractActive: isContractActive || false,
      presence: isContractActive ? (currentAbsence ? "Absent" : "Présent") : "Inactif",
      weekDays: getContractWeekDays(currentContract),
      currentContract,
      currentAbsence,
      slug: staff.slug,
      rawObject: { id: staff.id, first_name: staff.first_name, last_name: staff.last_name, domain: staff.domain, rank: staff.rank },
    }
  }), ["contractActive", "name"], ["desc", "asc"])
})

const activeStaffCount = computed(() => staffListValue.value.filter((s) => s.contractActive).length)
</script>

<template>
  <div class="staff-container">
    <div class="staff-header">
      {{ activeStaffCount }} compagnon{{ activeStaffCount > 1 ? "s" : "" }} actif{{ activeStaffCount > 1 ? "s" : "" }}
    </div>
    <DataTable
      :value="staffListValue"
      class="staff-table"
      :row-class="(data) => ({ 'staff-inactive': !data.contractActive })"
    >
      <Column
        field="name"
        header="Nom"
      >
        <template #body="slotProps">
          <RouterLink
            :to="{
              name: 'staff-detail',
              params: {
                companySlug: staff.env.company_slug,
                staffSlug_staffID: `${slotProps.data.slug}_${slotProps.data.rawObject.id}`
              }
            }"
            class="staff-name-link"
          >
            {{ slotProps.data.name }}
          </RouterLink>
        </template>
      </Column>
      <Column
        field="domain"
        header="Domaine"
      />
      <Column
        field="rank"
        header="Expérience"
      />
      <Column
        header="Type de contrat"
        field="contractType"
      />
      <Column header="Jour de travail">
        <template #body="slotProps">
          <div class="staff-week-days">
            <div
              v-for="(day, index) in slotProps.data.weekDays"
              :key="index"
              :class="[
                'staff-week-day-half-day',
                { '-morning': day.haldDays.morning},
                { '-afternoon': day.haldDays.afternoon},
              ]"
            >
              {{ day.name }}
            </div>
          </div>
        </template>
      </Column>
      <Column
        field="contractWeeklyHours"
        header="Heures hebdomadaires"
      />
      <Column header="Disponibilité">
        <template #body="slotProps">
          <Tag
            :value="slotProps.data.presence"
            :severity="getPresenceSeverity(slotProps.data.presence)"
          />
        </template>
      </Column>
      <Column>
        <template #body="slotProps">
          <StaffSpeedDial
            :staff="slotProps.data.rawObject"
            :current-contract="slotProps.data.currentContract"
            :current-absence="slotProps.data.currentAbsence"
            :presence="slotProps.data.presence"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../../../ui/static_src/ui/base/mixins';

.staff-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.staff-header {
  @include subtitle;
  padding: 1rem 1rem 0;
}

.staff-table {
  padding: 1rem;
}

.staff-week-days {
  position: relative;
  display: flex;
  gap: .4rem;
}

.staff-week-day-half-day {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  @include body;
  color: var(--color-text-quaternary);

  &.-morning {
    background: linear-gradient(to right, var(--color-text-submit-focus) 50%, var(--content-bg--color-dark) 50%);
  }

  &.-afternoon {
    background: linear-gradient(to left, var(--color-text-submit-focus) 50%, var(--content-bg--color-dark) 50%);
  }

  &.-morning.-afternoon {
    background: var(--color-text-submit-focus);
  }

  &:not(.-morning):not(.-afternoon) {
    background: var(--content-bg--color-dark);
  }
}

.staff-name-link {
  @include body;
  color: var(--color-text-primary);
  text-decoration: none;

  &:hover {
    text-decoration: underline !important;
    background-color: transparent !important;
  }
}
</style>
