import { createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "staff-list-empty" }
const _hoisted_2 = { class: "staff-list-empty-card" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["Hammer"], {
        size: "48",
        class: "staff-list-empty-icon"
      }),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "staff-list-empty-text" }, " Vous n'avez pas encore ajouté de compagnon à votre atelier ", -1 /* HOISTED */)),
      _createElementVNode("button", {
        class: "staff-list-empty-button",
        onClick: _cache[0] || (_cache[0] = $event => ($setup.staff.openStaffModal()))
      }, [
        _createVNode($setup["Plus"]),
        _cache[1] || (_cache[1] = _createTextVNode(" Ajouter un compagnon "))
      ])
    ])
  ]))
}