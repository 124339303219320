<script setup>
import Checkbox from "primevue/checkbox"

import { svgLink } from "../../../../../../ui/static_src/ui/utils/svg.js"

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(["update:modelValue"])

const updateModel = (key, value, event = null) => {
  if (event) {
    event.preventDefault()
    event.stopPropagation()
  }

  emit("update:modelValue", {
    ...props.modelValue,
    [key]: value,
  })
}
</script>

<template>
  <div class="car-incharge-form-block">
    <div class="car-form-subtitle">
      <svg class="car-form-subtitle-icon">
        <use :href="svgLink('cars', 'car-replacement')" />
      </svg>
      Remplacement
    </div>
    <button
      class="car-form-checkbox"
      @click="updateModel('need_replacement', !modelValue.need_replacement, $event)"
    >
      <Checkbox
        :model-value="modelValue.need_replacement"
        input-id="need_replacement"
        name="need_replacement"
        :binary="true"
        class="checkbox-car-incharge"
      />
      <label
        for="need_replacement"
        style="cursor: pointer; color: var(--color-text-secondary);"
      >Le propriétaire souhaite un véhicule de remplacement</label>
    </button>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../../../../ui/static_src/ui/base/mixins';

.car-incharge-form-block {
  display: flex;
  flex-direction: column;
  padding: .4rem 0rem;
  gap: 1rem;
  width: 100%;

  &.-row {
    flex-direction: row;
    justify-content: space-between;
  }

  &.-no-padding {
    padding: 0;
  }
}

.car-form-subtitle {
  @include subtitle;
  display: flex;
  align-items: center;
  gap: .4rem;
  margin-top: .4rem;
}

.car-form-subtitle-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.car-form-checkbox {
  display: flex;
  align-items: center;
  gap: .6rem;
  width: fit-content;
  padding-inline: 0;
  padding-block: 0;

  &:hover {
    background-color: var(--content-bg--color-lighter);
  }
}
</style>
